/* eslint-disable prefer-template */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/no-danger */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable prefer-const */
/* eslint-disable max-len */
/* eslint-disable eqeqeq */
/* eslint-disable no-new */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-unused-vars */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable max-lines */
/* eslint-disable no-console */
/* eslint-disable fp/no-let */
/* eslint-disable react/jsx-no-bind */
import {
    faBars,
    faCarGarage,
    faCartShopping,
    faChevronDown,
    faGlobe,
    faShippingFast,
    faThumbsUp,
    faUser
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { lazy, Suspense } from 'react';
// import ReactPixel from 'react-facebook-pixel';
// import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';

import CartIcon from 'Component/CartIcon';
import ChevronIcon from 'Component/ChevronIcon';
import { LEFT } from 'Component/ChevronIcon/ChevronIcon.config';
import ClickOutside from 'Component/ClickOutside';
import CloseIcon from 'Component/CloseIcon';
import CountrySelector from 'Component/CountrySelector';
import CustomerGarageHeader from 'Component/CustomerGarageHeader';
import HeaderMenu from 'Component/HeaderMenu';
import LanguageSelector from 'Component/LanguageSelector';
import Link from 'Component/Link';
import Logo from 'Component/Logo';
import MenuIcon from 'Component/MenuIcon';
import MenuOverlay from 'Component/MenuOverlay';
import { DEFAULT_STATE_NAME } from 'Component/NavigationAbstract/NavigationAbstract.config';
import OfflineNotice from 'Component/OfflineNotice';
import SearchField from 'Component/SearchField';
import TopBannerContainer from 'Component/TopBanner/index';
import UserIcon from 'Component/UserIcon';
import { Header as SourceHeader } from 'SourceComponent/Header/Header.component';
import Html from 'SourceComponent/Html';
import { DeviceType } from 'Type/Device.type';
import { TotalsType } from 'Type/MiniCart.type';
import { isSignedIn } from 'Util/Auth';
import { getCurrency } from 'Util/Currency';
import {
    isAusStore, isEurStore, isIntStore, isRsaStore, isUsaStore
} from 'Util/FrontRunner/Store';
import {
    getBlackFridayPromo,
    getSalesPromotion,
    getXmasPromo
} from 'Util/FrontRunner/Store/Promotions';
import { isB2BUser, isProduction, md5 } from 'Util/Helper';
import { history as browserHistory } from 'Util/History';
import media from 'Util/Media';
import { LOGO_MEDIA } from 'Util/Media/Media';

import {
    CART,
    CART_EDITING,
    CART_OVERLAY,
    CATEGORY,
    CHECKOUT,
    CHECKOUT_ACCOUNT,
    CHECKOUT_SUCCESS,
    CMS_PAGE,
    CONTACT_US,
    CUSTOMER_ACCOUNT,
    CUSTOMER_ACCOUNT_PAGE,
    CUSTOMER_SUB_ACCOUNT,
    CUSTOMER_WISHLIST,
    FILTER,
    LANG_SWITCHER,
    MENU,
    MENU_SUBCATEGORY,
    NO_MATCH,
    PDP,
    POPUP,
    PRODUCT_COMPARE,
    SEARCH,
    STORE_SWITCHER,
    VEHICLE_GARAGE
} from './Header.config';

import './Header.style';

// const advancedMatching = { em: 'yks.nit@gmail.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
export const advancedMatching = null; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
export const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false // enable logs
};
export const CartOverlay = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "overlay" */ 'Component/CartOverlay'
));
// ReactPixel.init(window.storeConfig.facebookPixelCode, advancedMatching, options);

// ReactPixel.pageView(); // For tracking page view
// ReactPixel.track( event, data ) // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
// ReactPixel.trackCustom( event, data );

// export const googleAnalyticsAppId = window.storeConfig.googleAnalyticsAppId || 'UA-15598843-6';
export const cookies = new Cookies();
export const subAccountsConfig = {
    4: {
        b2c: 'UA-15598843-16',
        b2b: 'UA-15598843-15'
    },
    5: {
        b2c: 'UA-13211700-2',
        b2b: 'UA-13211700-3'
    },
    7: {
        b2c: 'UA-15598843-18',
        b2b: 'UA-15598843-17'
    },
    8: {
        b2c: 'UA-64284618-7',
        b2b: 'UA-64284618-6'
    },
    9: {
        b2c: 'UA-64284618-5',
        b2b: 'UA-64284618-4'
    },
    11: {
        b2c: 'UA-15598843-30',
        b2b: 'UA-15598843-29'
    },
    12: {
        b2c: 'UA-15598843-24',
        b2b: 'UA-15598843-23'
    },
    14: {
        b2c: 'UA-15598843-28',
        b2b: 'UA-15598843-27'
    },
    15: {
        b2c: 'UA-15598843-26',
        b2b: 'UA-15598843-25'
    },
    16: {
        b2c: 'UA-15598843-20',
        b2b: 'UA-15598843-19'
    },
    17: {
        b2c: 'UA-15598843-22',
        b2b: 'UA-15598843-21'
    }
};

export const subAccountsGlobalConfig = {
    b2c: 'UA-15598843-32',
    b2b: 'UA-15598843-31'
};

// export const subAccountsConfig__test = {
//     4: {
//         b2c: 'UA-221748263-3',
//         b2b: 'UA-221748263-4'
//     },
//     5: {
//         b2c: 'UA-221748263-6',
//         b2b: 'UA-221748263-7'
//     },
//     8: {
//         b2c: 'UA-221748263-9',
//         b2b: 'UA-221748263-10'
//     }
// };

export const subAccountsConfig__test = {
    4: {
        b2c: 'UA-221748263-3',
        b2b: 'UA-221748263-4'
    },
    5: {
        b2c: 'UA-221748263-6',
        b2b: 'UA-221748263-7'
    },
    7: {
        b2c: 'UA-223669644-1',
        b2b: 'UA-223693681-1'
    },
    8: {
        b2c: 'UA-221748263-9',
        b2b: 'UA-221748263-10'
    },
    9: {
        b2c: 'UA-223665309-1',
        b2b: 'UA-223682581-1'
    },
    11: {
        b2c: 'UA-223703549-1',
        b2b: 'UA-223665412-1'
    },
    12: {
        b2c: 'UA-223708895-1',
        b2b: 'UA-223698646-1'
    },
    14: {
        b2c: 'UA-223708897-1',
        b2b: 'UA-223686129-1'
    },
    15: {
        b2c: 'UA-223652291-1',
        b2b: 'UA-223697539-1'
    },
    16: {
        b2c: 'UA-223705169-1',
        b2b: 'UA-223686829-1'
    },
    17: {
        b2c: 'UA-223710513-1',
        b2b: 'UA-223667731-1'
    }
};
export const subAccountsGlobalConfig__test = {
    b2c: 'UA-221748263-11',
    b2b: 'UA-221748263-12'
};

/** @namespace Pwa/Component/Header/Component/HeaderComponent */
export class HeaderComponent extends SourceHeader {
    static propTypes = {
        navigationState: PropTypes.object.isRequired,
        cartTotals: TotalsType.isRequired,
        onBackButtonClick: PropTypes.func.isRequired,
        onCloseButtonClick: PropTypes.func.isRequired,
        onSearchBarFocus: PropTypes.func.isRequired,
        onClearSearchButtonClick: PropTypes.func.isRequired,
        onMyAccountButtonClick: PropTypes.func.isRequired,
        onSearchBarChange: PropTypes.func.isRequired,
        isWishlistLoading: PropTypes.bool.isRequired,
        onEditButtonClick: PropTypes.func.isRequired,
        onMinicartButtonClick: PropTypes.func.isRequired,
        onOkButtonClick: PropTypes.func.isRequired,
        onCancelButtonClick: PropTypes.func.isRequired,
        onSearchOutsideClick: PropTypes.func.isRequired,
        onMyAccountOutsideClick: PropTypes.func.isRequired,
        onMinicartOutsideClick: PropTypes.func.isRequired,
        shouldRenderGarageOverlay: PropTypes.bool.isRequired,
        isClearEnabled: PropTypes.bool.isRequired,
        searchCriteria: PropTypes.string.isRequired,
        shareWishlist: PropTypes.func.isRequired,
        header_logo_src: PropTypes.string,
        logo_alt: PropTypes.string,
        logo_height: PropTypes.number,
        logo_width: PropTypes.number,
        isLoading: PropTypes.bool,
        showMyAccountLogin: PropTypes.bool,
        isCheckout: PropTypes.bool.isRequired,
        onSignIn: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        device: DeviceType.isRequired,
        flag: PropTypes.object,
        headerNotifications: PropTypes.any
    };

    static defaultProps = {
        logo_alt: 'ScandiPWA logo',
        logo_height: 25,
        logo_width: 200,
        showMyAccountLogin: false,
        header_logo_src: '',
        flag: {},
        isLoading: true
    };

    stateMap = {
        [DEFAULT_STATE_NAME]: {
            mobileMenu: true,
            title: true,
            logo: true,
            mobileStoreSwitcher: true,
            storeSwitcher: true
        },
        [NO_MATCH]: {
            title: true
        },
        [POPUP]: {
            title: true,
            close: true
        },
        [PDP]: {
            back: true,
            title: true
        },
        [CATEGORY]: {
            back: true,
            title: true
        },
        [CUSTOMER_ACCOUNT]: {
            title: true
        },
        [CUSTOMER_SUB_ACCOUNT]: {
            title: true,
            back: true
        },
        [CUSTOMER_ACCOUNT_PAGE]: {
            title: true
        },
        [CUSTOMER_WISHLIST]: {
            share: true,
            title: true,
            edit: true,
            ok: true
        },
        [MENU]: {
            search: true
        },
        [MENU_SUBCATEGORY]: {
            back: true,
            title: true
        },
        [SEARCH]: {
            back: true,
            search: true
        },
        [CART]: {
            title: true
        },
        [CART_OVERLAY]: {
            title: true
        },
        [CART_EDITING]: {
            ok: true,
            title: true,
            cancel: true
        },
        [FILTER]: {
            close: true,
            clear: true,
            title: true
        },
        [CHECKOUT]: {
            back: true,
            title: true,
            account: true
        },
        [CHECKOUT_SUCCESS]: {
            title: true,
            account: true
        },
        [CHECKOUT_ACCOUNT]: {
            title: true,
            close: true
        },
        [CMS_PAGE]: {
            back: true,
            title: true
        },
        [CONTACT_US]: {
            title: true,
            back: true
        },
        [PRODUCT_COMPARE]: {
            title: true,
            back: true
        },
        [STORE_SWITCHER]: {
            //  back: true,
            storeSwitcher: true,
            close: true,
            logo: true
            // ok: true,
            // title: true,
        },
        [LANG_SWITCHER]: {
            langSwitcher: true,
            close: true,
            logo: true
        },
        [VEHICLE_GARAGE]: {
            vehicleGarage: true,
            close: true
        }
    };

    renderMap = {
        cancel: this.renderCancelButton.bind(this),
        back: this.renderBackButton.bind(this),
        close: this.renderCloseButton.bind(this),
        share: this.renderShareWishListButton.bind(this),
        title: this.renderTitle.bind(this),
        logo: this.renderLogo.bind(this),
        account: this.renderAccount.bind(this),
        minicart: this.renderMinicart.bind(this),
        // compare: this.renderComparePageButton.bind(this),
        search: this.renderSearchField.bind(this),
        ok: this.renderOkButton.bind(this),
        menu: this.renderMenuButton.bind(this),
        mobileMenu: this.renderMobileMenu.bind(this),
        storeSwitcher: this.renderStoreSwitcher.bind(this),
        langSwitcher: this.renderLangSwitcher.bind(this),
        vehicleGarage: this.renderVehicleGarage.bind(this)
    };

    state = {
        temp: '',
        isOpen: false
    };

    __construct(props) {
        super.__construct(props);
        this.ref = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        this.storeIdLookupAndRequestUpdateHeader();
        document.addEventListener('click', this.handleClickOutside, true);
        // below code will work to connect GTM with configured testing data layer for analytics
        // const tagManagerArgs = {
        //     gtmId: 'GTM-WB4XNCS',
        //     dataLayer: {
        //         subAccountGlobal: 'UA-221748263-1'
        //     }
        // };

        // TagManager.initialize(tagManagerArgs);
    }

    componentDidUpdate(prevProps) {
        // this.checkAndResolveCurrency();
    }

    handleClickOutside(event) {
        if (this.ref.current && !this.ref.current.contains(event.target)) {
            const { onVehicleGarageButtonClick } = this.props;
            onVehicleGarageButtonClick();
        }
    }

    renderTitle(isVisible = false) {
        const { navigationState: { title } } = this.props;

        // return (
        //     <h2
        //       key="title"
        //       block="Header"
        //       elem="Title"
        //       mods={ { isVisible } }
        //     >
        //         { title }
        //     </h2>
        // );
        return null;
    }

    renderSearchField(isVisible = false) {
        const {
            searchCriteria,
            onSearchOutsideClick,
            onSearchBarFocus,
            onSearchBarChange,
            onClearSearchButtonClick,
            navigationState: { name },
            isCheckout,
            hideActiveOverlay,
            device: { isMobile }
        } = this.props;

        if (isCheckout && !isMobile) {
            return null;
        }

        const baseUrl = window.location.pathname;
        if (baseUrl.includes('/search')) {
            return null;
        }

        return (
            <SearchField
              key="search"
              searchCriteria={ searchCriteria }
              onSearchOutsideClick={ onSearchOutsideClick }
              onSearchBarFocus={ onSearchBarFocus }
              onSearchBarChange={ onSearchBarChange }
              onClearSearchButtonClick={ onClearSearchButtonClick }
              isVisible={ isVisible }
              isActive={ name === SEARCH }
              hideActiveOverlay={ hideActiveOverlay }
            />
        );
    }

    renderBackButton(isVisible = false) {
        const { onBackButtonClick, device: { isMobile } } = this.props;

        if (!isMobile || isMobile) {
            return null;
        }

        return (
            <button
              key="back"
              block="Header"
              elem="Button"
              mods={{ type: 'back', isVisible }}
              onClick={onBackButtonClick}
              aria-label="Go back"
              aria-hidden={!isVisible}
              tabIndex={isVisible ? 0 : -1}
            >
                <ChevronIcon direction={LEFT} />
            </button>
        );
    }

    // @deprecated
    // checkAndResolveCurrency = () => {
    //     const { customer = {} } = this.props;
    //     const { is_b2b = '' } = customer;
    //     const activeCurrencyCode = getCurrency();
    //     if (is_b2b && isB2BUser(is_b2b) && window.storeConfig.baseName.includes('en/uk') && activeCurrencyCode !== 'EUR') {
    //         const { onForceCurrencyUpdate } = this.props;
    //         onForceCurrencyUpdate('EUR'); // only for en/uk b2b customer
    //     }
    // };

    setInitialGAData() {
        const { customer = {} } = this.props;
        const { is_b2b = '', email } = customer;

        window.dataLayer = window.dataLayer || [];
        let payload = {};

        // Original location
        // GTM/Adwords Rogue Referral Fix
        // https://getridbug.com/webmasters/adwords-conversion-tracking-on-a-single-page-react-app/
        const origLocKey = 'originalLocation';
        const _ol = `${document.location.protocol }//${document.location.hostname}${document.location.pathname}${document.location.search}`;
        const sessionItem = sessionStorage.getItem(origLocKey);
        if (sessionItem) {
            payload.originalLocation = sessionItem;
        } else {
            sessionStorage.setItem(origLocKey, _ol);
            payload.originalLocation = _ol;
        }

        // Set User Type
        let userType = 'B2C';
        if (is_b2b && isB2BUser(is_b2b)) {
            userType = 'B2B';
        }
        // Check if B2B customer (Use local storage check)
        const isB2Bsession = JSON.parse(sessionStorage.getItem('is_b2b')) ?? false;
        if (isB2Bsession) {
            userType = 'B2B';
        }
        payload.user_type = userType;

        // Set store information - possibly used for dimensions
        payload.store_code = window?.storeConfig?.code ?? null;
        payload.store_base_url = window?.storeConfig?.baseName ?? null;

        // Set Algolia User Token
        // let userToken = '';
        // if (isSignedIn()) {
        //     const { email } = customer;
        //     userToken = md5(email);
        // } else {
        //     userToken = cookies.get('_ALGOLIA', '');
        // }

        // Set Algolia User Token
        let userToken = '';
        if (isSignedIn() && email) {
            userToken = md5(email);
        } else {
            userToken = cookies.get('_ALGOLIA', '') || localStorage.getItem('unicUId', '');
            if (!userToken) {
                userToken = `anonymous-${uuidv4()}`;
                localStorage.setItem('unicUId', userToken);
            }
        }
        window.dataLayer.push({
            algoliaUserToken: userToken
        });

        // Push to dataLayer
        window.dataLayer.push(payload);
    }

    getScript = (id, dataLayerName) => `(function(w,d,s,l,i){w[l]=w[l]||[];
        w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='${dataLayerName}'?'&l='+l:'';j.async=true;j.src=
        'https://www.frontrunneroutfitters.com/securemetrics/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','${dataLayerName}','${id}');`;

        storeIdLookupAndRequestUpdateHeader = () => {
            const period = 600; // ms
            const sleepyAlert = setInterval(() => {
                const { storeId } = window.storeConfig;
                if (storeId !== undefined) {
                    clearInterval(sleepyAlert);
                    const { customer = {} } = this.props;
                    const { is_b2b = '' } = customer;
                    const { is_b2b: prev_is_b2b, subAccountsConfigData: prev_subAccountsConfigData, storeId: prev_StoreId } = this.state;

                    // Set B2C Local storage
                    let isB2BValue = is_b2b;
                    const isB2BKey = 'is_b2b';
                    const isB2Bstorage = sessionStorage.getItem(isB2BKey);
                    if (!isB2Bstorage || isB2BUser(is_b2b)) {
                        sessionStorage.setItem(isB2BKey, isB2BUser(is_b2b) ? 'true' : 'false');
                    }
                    isB2BValue = JSON.parse(sessionStorage.getItem(isB2BKey)) ?? false;

                    const subAccountType = isB2BValue ? 'b2b' : 'b2c';
                    const subAccountsConfigData = !isEmpty(subAccountsConfig[storeId]) ? subAccountsConfig[storeId][subAccountType] : '';
                    if ((is_b2b != prev_is_b2b && subAccountsConfigData != prev_subAccountsConfigData) || storeId != prev_StoreId) {
                        this.setState({ is_b2b, subAccountsConfigData, storeId });

                        let tagManagerArgs = {};
                        if (isProduction()) {
                            tagManagerArgs = {
                                gtmId: 'GTM-NWFBNDKB',
                                dataLayer: {
                                    subAccountGlobal: subAccountsGlobalConfig[subAccountType],
                                    subAccount: subAccountsConfigData
                                }
                            };
                        } else {
                            const subAccountsConfigData__test = !isEmpty(subAccountsConfig__test[storeId]) ? subAccountsConfig__test[storeId][subAccountType] : '';
                            tagManagerArgs = {
                                gtmId: 'GTM-WB4XNCS',
                                dataLayer: {
                                    subAccountGlobal: subAccountsGlobalConfig__test[subAccountType],
                                    subAccount: subAccountsConfigData__test
                                }
                            };
                        }

                        // Set original location before GTM is initialized
                        this.setInitialGAData();

                        // Initialize Tag Manager
                        if (!isProduction()) {
                            TagManager.initialize(tagManagerArgs);
                        }
                    }

                    // Create and append noscript and iframe for GTM
                    // this.createAndAppendNoscript();

                    // Create and append script for GTM
                    this.createAndAppendScript('GTM-5R4FJHS', 'dataLayer');
                }
            }, period);
        };

        createAndAppendNoscript = () => {
            this.noscript = document.createElement('noscript');
            const iframe = document.createElement('iframe');
            iframe.src = 'https://www.frontrunneroutfitters.com/securemetrics/gtm.js?id=GTM-5R4FJHS';
            iframe.height = '0';
            iframe.width = '0';
            iframe.style.display = 'none';
            iframe.style.visibility = 'hidden';
            this.noscript.appendChild(iframe);
            if (process.env.NODE_ENV === 'production' && window.location.origin.includes('www.frontrunneroutfitters.com')) {
                document.body.insertBefore(this.noscript, document.body.firstChild);
            }
        };

        createAndAppendScript = (gtmId, dataLayerName) => {
            const script = document.createElement('script');
            script.innerHTML = this.getScript(gtmId, dataLayerName);
            if (process.env.NODE_ENV === 'production' && window.location.origin.includes('www.frontrunneroutfitters.com')) {
                document.head.insertBefore(script, document.head.childNodes[0]);
            }
        };

        renderLogoImage() {
            const {
                header_logo_src,
                logo_alt
            } = this.props;

            return (
            <Logo
              src={media(header_logo_src, LOGO_MEDIA)}
              alt={logo_alt}
            />
            );
        }

        renderLogoImg() {
            const baseUrl = window.location.pathname;
            const { isCheckout, device: { isMobile } } = this.props;

            if (isCheckout && !isMobile) {
                return (
                <img
                  src="/media/logo/logo-w.svg"
                  alt="Front Runner | Off-Road Tough Roof Racks &amp; Vehicle Adventure Gear"
                  width="110"
                  height="29"
                  fetchpriority="high"
                />
                );
            }
            // if (baseUrl.includes('checkout')) {
            //     return (
            //         <img
            //           src="/media/logo/logo-w.svg"
            //           alt="Front Runner | Off-Road Tough Roof Racks &amp; Vehicle Adventure Gear"
            //           width="110"
            //           height="29"
            //           fetchpriority="high"
            //         />
            //     );
            // }

            return (
            <img
              src="/media/logo/logo.svg"
              alt="Front Runner | Off-Road Tough Roof Racks &amp; Vehicle Adventure Gear"
              width="144"
              height="56"
              fetchpriority="high"
            />
            );
        }

    moveToTop = () => {
        const { onHeaderMenuClick } = this.props;
        onHeaderMenuClick();
        browserHistory.push('/');
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    renderLogo(isVisible = false) {
        return (
            <Link
              to="/"
              aria-label="Go to homepage"
            //   aria-hidden
              tabIndex={isVisible ? 0 : 1}
            //   block="Header"
              onClick={this.moveToTop}
            //   elem="LogoWrapper"
            //   mods={{ isVisible }}
              className=" Header-LogoWrapper Header-LogoWrapper_isVisible"
              key="logo"
              itemScope
              itemType="http://schema.org/Organization"
            >
                { /* this.renderLogoImage() */}
                {this.renderLogoImg()}
            </Link>
        );
    }

    renderCloseButton(isVisible = false) {
        const { onCloseButtonClick, device } = this.props;
        // if (device.isMobile) {
        //     return null;
        // }

        return (
            <button
              key="close"
              block="Header"
              elem="Button"
              mods={{ type: 'close', isVisible }}
              onClick={onCloseButtonClick}
              aria-label="Close"
              aria-hidden={!isVisible}
              tabIndex={isVisible ? 0 : -1}
            >
                <CloseIcon />
            </button>
        );
    }

    renderMenuButton(isVisible = false) {
        const { onMenuOutsideClick, device, onMenuButtonClick } = this.props;
        if (device.isMobile) {
            return null;
        }

        return (
            <div>
                <button
                  block="Header"
                  elem="Button"
                  mods={{ isVisible, type: 'menu' }}
                  aria-label="Go to menu and search"
                  aria-hidden={!isVisible}
                  tabIndex={isVisible ? 0 : -1}
                  onClick={onMenuButtonClick}
                />
                <MenuOverlay />
            </div>
        );
    }

    renderAccountButton() {
        const {
            onMyAccountButtonClick,
            device
        } = this.props;

        if (device.isMobile) {
            return null;
        }

        if (isSignedIn()) {
            return (
                <>
                    {/* {this.renderStoreSwitcher()} */}
                    <button
                      block="Header"
                      elem="MyAccountWrapper"
                      tabIndex="0"
                      ref={(accountbutton) => this.accountbutton = accountbutton}
                      onClick={onMyAccountButtonClick}
                      aria-label="Open my account"
                      id="myAccount"
                    >
                        <UserIcon />
                        <span>
                            {__('My Account')}
                        </span>
                    </button>
                    { this.renderWishListLink() }
                    { this.renderContactUsLink() }
                </>
            );
        }

        return (
            <>
                {/* {this.renderStoreSwitcher()} */}
                <button
                  block="Header"
                  elem="MyAccountWrapper"
                  tabIndex="0"
                  ref={(accountbutton) => this.accountbutton = accountbutton}
                  onClick={onMyAccountButtonClick}
                  aria-label="Open my account"
                  id="myAccount"
                >
                    <FontAwesomeIcon icon={ faUser } size="1x" color="#fff" />
                    <span>
                        {__('Login / Register')}
                    </span>
                </button>
                {/* {this.renderWishListLink()} */}
                { this.renderContactUsLink() }
            </>
        );
    }

    renderAccountMobileButton() {
        const {
            onMyAccountButtonClick,
            device
        } = this.props;

        if (!device.isMobile) {
            return null;
        }

        if (isSignedIn()) {
            return (
                <Link
                  to="/my-account/"
                  block="myAcct login"
                  elem="elem"
                >
                    <UserIcon />
                    <span>
                        {__('My Account')}
                    </span>
                </Link>
            );
        }

        return (
            <Link
              to="/login/"
              block="login"
              elem="elem"
            >
              <FontAwesomeIcon icon={ faUser } size="1x" color="#fff" />
                <span>
                    {__('Login / Register')}
                </span>
            </Link>
        );
    }

    renderAccount(isVisible = false) {
        const {
            onMyAccountOutsideClick,
            isCheckout,
            device: { isMobile }
        } = this.props;

        // on mobile hide button if not in checkout
        // if (!isCheckout) {
        //     return null;
        // }

        if (isCheckout && isSignedIn()) {
            return null;
        }

        return (
            <>
                { this.renderWelcomeMessage() }
                <ClickOutside
                  onClick={ onMyAccountOutsideClick }
                  key="account"
                >
                    <div
                      aria-label="My account"
                      block="Header"
                      elem="MyAccount"
                    >
                        { this.renderAccountButton(isVisible) }
                        { this.renderAccountOverlay() }
                    </div>
                </ClickOutside>
            </>
        );
    }

    menuMobile = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        browserHistory.push('/menu');
    };

    menuMobileOverlay = () => {
        const { onHeaderOverMenuClick } = this.props;
        onHeaderOverMenuClick();
    };

    renderMobileMenu(isVisible = false) {
        const { device } = this.props;

        if (!device.isMobile) {
            return;
        }

        // eslint-disable-next-line consistent-return
        return (
            <button
            //   onClick={this.menuMobile}
              onClick={this.menuMobileOverlay}
              key="mobileMenu"
              className="NavigationTabs-menu"
              aria-label="Mobile Menu"
            >
                <FontAwesomeIcon icon={ faBars } size="1x" color="#fff" />
            </button>
        );
    }

    renderStoreSwitcher(isVisible = false) {
        // if (!isVisible) {
        //     return;
        // }
        const {
            onStoreSwitcherOutsideClick, activeOverlay, onStoreSwitcherClick, flag: { src = '' }, default_country, code, device: { isMobile }
        } = this.props;

        if (isMobile) {
            return null;
        }
        // TODO: Change default flag to loading placeholder
        const defaultFlagSrc = '/media/skin/images/flags/us.png';
        const flagSrc = isEmpty(src) ? defaultFlagSrc : src;
        let storeLanguage = window.storeConfig.lang ?? 'en';
        storeLanguage = storeLanguage.substring(0, 2);
        const countryCode = window.storeConfig.code;

        // let flagIcon = {};
        // if (window.storeConfig.code === 'en_int') {
        //     flagIcon = <FontAwesomeIcon icon={['fas', 'globe-americas']} />;
        // } else {
        //     flagIcon = (
        //             <img
        //               alt="Flage"
        //               src={flagSrc}
        //               loading="eager"
        //               width="32"
        //               height="32"
        //             />
        //     );
        // }

        const mapCountry = {
            en_us: {
                region: 'US',
                flag: '/media/skin/images/flags/us.png'
            },
            es_us: {
                region: 'US',
                flag: '/media/skin/images/flags/us.png'
            },
            en_za: {
                region: 'ZA',
                flag: '/media/skin/images/flags/za.png'
            },
            en_uk: {
                region: 'GB',
                flag: '/media/skin/images/flags/gb.png'
            },
            en_de: {
                region: 'EU',
                flag: '/media/skin/images/flags/europeanunion.png'
            },
            de_de: {
                region: 'EU',
                flag: '/media/skin/images/flags/europeanunion.png'
            },
            fr_be: {
                region: 'EU',
                flag: '/media/skin/images/flags/europeanunion.png'
            },
            es_be: {
                region: 'EU',
                flag: '/media/skin/images/flags/europeanunion.png'
            },
            nl_be: {
                region: 'EU',
                flag: '/media/skin/images/flags/europeanunion.png'
            },
            en_au: {
                region: 'AU',
                flag: '/media/skin/images/flags/au.png'
            },
            en_int: {
                region: 'Global',
                flag: '/media/skin/images/flags/globe.png'
            }
        };

        return (
            <>
                <button
                  block="Header"
                  elem="Button"
                  mods={{ isVisible, type: 'storeSwitcher' }}
                  aria-label="Go to Country Switcher"
                  aria-hidden={!isVisible}
                  tabIndex={isVisible ? 0 : -1}
                  onClick={onStoreSwitcherClick}
                >
                    {countryCode === 'en_int' ? <FontAwesomeIcon icon={ faGlobe } /> : <img src={mapCountry[countryCode].flag} alt={ `Front Runner ${countryCode}` } width="26px" height="26px" fetchpriority="high" />}
                    <span
                      block="Header"
                      elem="Button"
                      mods={{ isVisible, type: 'storeSwitcherText' }}
                    >
                        { mapCountry[countryCode].region }
                        { ' ' }
                        { `(${storeLanguage})` }
                    </span>
                    <FontAwesomeIcon icon={ faChevronDown } />
                </button>
                {
                    activeOverlay === 'storeSwitcher'
                        ? <CountrySelector flagSrc={src} onStoreSwitcherOutsideClick={onStoreSwitcherOutsideClick} />
                        : ''
                }
            </>
        );
    }

    renderStoreSwitcherOver(isVisible = true) {
        const {
            onStoreSwitcherOutsideClick, activeOverlay, onStoreSwitcherClick, flag: { src = '' }, default_country, code, device: { isMobile }
        } = this.props;

        const defaultFlagSrc = '/media/skin/images/flags/us.png';
        const flagSrc = isEmpty(src) ? defaultFlagSrc : src;
        let storeLanguage = window.storeConfig.lang ?? 'en';
        storeLanguage = storeLanguage.substring(0, 2);
        const countryCode = window.storeConfig.code;

        const mapCountry = {
            en_us: {
                region: 'US',
                flag: '/media/skin/images/flags/us.png'
            },
            es_us: {
                region: 'US',
                flag: '/media/skin/images/flags/us.png'
            },
            en_za: {
                region: 'ZA',
                flag: '/media/skin/images/flags/za.png'
            },
            en_uk: {
                region: 'GB',
                flag: '/media/skin/images/flags/gb.png'
            },
            en_de: {
                region: 'EU',
                flag: '/media/skin/images/flags/europeanunion.png'
            },
            de_de: {
                region: 'EU',
                flag: '/media/skin/images/flags/europeanunion.png'
            },
            fr_be: {
                region: 'EU',
                flag: '/media/skin/images/flags/europeanunion.png'
            },
            es_be: {
                region: 'EU',
                flag: '/media/skin/images/flags/europeanunion.png'
            },
            nl_be: {
                region: 'EU',
                flag: '/media/skin/images/flags/europeanunion.png'
            },
            en_au: {
                region: 'AU',
                flag: '/media/skin/images/flags/au.png'
            },
            en_int: {
                region: 'Global',
                flag: '/media/skin/images/flags/globe.png'
            }
        };

        return (
            <>
                <button
                  block="Header"
                  elem="Button"
                  mods={{ isVisible, type: 'storeSwitcher' }}
                  aria-label="Go to Country Switcher"
                  aria-hidden={!isVisible}
                  tabIndex={isVisible ? 0 : -1}
                  onClick={onStoreSwitcherClick}
                >
                    {countryCode === 'en_int' ? <FontAwesomeIcon icon={ faGlobe } /> : <img src={mapCountry[countryCode].flag} alt={ `Front Runner ${countryCode}` } width="26px" height="26px" fetchpriority="high" />}
                    <span
                      block="Header"
                      elem="Button"
                      mods={{ isVisible, type: 'storeSwitcherText' }}
                    >
                        { mapCountry[countryCode].region }
                        { ' ' }
                        { `(${storeLanguage})` }
                    </span>
                    <FontAwesomeIcon icon={ faChevronDown } />
                </button>
                {
                    activeOverlay === 'storeSwitcher'
                        ? <CountrySelector flagSrc={src} onStoreSwitcherOutsideClick={onStoreSwitcherOutsideClick} />
                        : ''
                }
            </>
        );
    }

    renderLangSwitcher() {
        const { onLangSwitcherOutsideClick, onLangSwitcherClick } = this.props;

        return (
            <LanguageSelector />
        );
    }

    renderMenu() {
        const { device, isCheckout, headerMenuClick } = this.props;

        if (device.isMobile || isCheckout) {
            return null;
        }

        return <HeaderMenu headerMenuClick={headerMenuClick} />;
    }

    renderWishListLink() {
        let linkTo = '/my-account/my-wishlist';
        if (!isSignedIn()) {
            linkTo = '/login';
        }

        // window.scrollTo({
        //     top: 0,
        //     behavior: 'smooth'
        // });

        const { productsInWishlist } = this.props;
        let counter = false;
        let totalItems = 0;

        if (productsInWishlist) {
            counter = true;
            totalItems = Object.keys(productsInWishlist).length;
        }

        const { requestCustomerData } = this.props;
        const { customer = {} } = this.props;
        const { is_b2b } = customer;

        return (
            <>
                <Link
                  to={linkTo}
                  block="wishlist"
                  elem="elem"
                  onClick={this.moveToTop}
                >
                    {__('Wishlist')}
                    {(totalItems > 0 && counter) && (
                        <div className="WishlistCounter">
                            {totalItems}
                        </div>
                    )}
                </Link>
                {this.renderQuickLink()}
            </>
        );
    }

    renderQuickLink() {
        let linkTo = '/quickorder/';
        this.storeIdLookupAndRequestUpdateHeader();
        const { customer = {} } = this.props;
        const { is_b2b } = customer;
        if (isEmpty(is_b2b) || is_b2b == 'false' || is_b2b == false) {
            return null;
        }

        if (!isSignedIn()) {
            linkTo = '/login';
        }

        return (
            <Link
              to="/quickorder/"
              block="quickorder"
              elem="elem"
            >
                {__('Quick Order')}
            </Link>
        );
    }

    renderContactUsLink() {
        return (
            <Link
              to="/contact"
              block="contact"
              elem="elem"
              onClick={this.moveToTop}
            >
                <FontAwesomeIcon icon={['fas', 'envelope']} />
                {' '}
                {__('Contact')}
            </Link>
        );
    }

    updateName = (codeName) => {
        // this.setState({ codeName });
        // localStorage.setItem('code_name', codeName);
    };

    generateCurrentRedirectUrl(language, country) {
        const baseUrl = `${window.location.protocol
        }//${window.location.host
        }`;

        const pathArray = window.location.pathname.split('/');
        pathArray[1] = language;
        pathArray[2] = country;

        return baseUrl + pathArray.join('/');
    }

    renderLanguageSw() {
        const codeName = window.storeConfig.langHtml || 'en';
        const { onStoreSwitcherClick } = this.props;
        const baseUrl = window.location.pathname;

        // if (baseUrl.includes('checkout')) {
        //     return (
        //         <div className="switcher-language-wrap">
        //             {this.renderSipingCont()}
        //         </div>
        //     );
        // }
        // if (isEurStore(true)) {
        //     return (
        //         <div className="switcher-language-wrap">
        //             <div className="switcher-language">
        //                 <div className="switcher-trigger">
        //                     {codeName}
        //                 </div>
        //                 <ul>
        //                     {(codeName !== 'English') && (
        //                         <li className="switcher-option">
        //                             <a href={this.generateCurrentRedirectUrl('en', 'de')} onClick={() => this.updateName('English')}>English</a>
        //                         </li>
        //                     )}
        //                     {(codeName !== 'German') && (
        //                         <li className="switcher-option">
        //                             <a href={this.generateCurrentRedirectUrl('de', 'de')} onClick={() => this.updateName('German')}>German</a>
        //                         </li>
        //                     )}
        //                     {(codeName !== 'French') && (
        //                         <li className="switcher-option">
        //                             <a href={this.generateCurrentRedirectUrl('fr', 'be')} onClick={() => this.updateName('French')}>French</a>
        //                         </li>
        //                     )}
        //                     {(codeName !== 'Spanish') && (
        //                         <li className="switcher-option">
        //                             <a href={this.generateCurrentRedirectUrl('es', 'be')} onClick={() => this.updateName('Spanish')}>Spanish</a>
        //                         </li>
        //                     )}
        //                     {(codeName !== 'Dutch') && (
        //                         <li className="switcher-option">
        //                             <a href={this.generateCurrentRedirectUrl('nl', 'be')} onClick={() => this.updateName('Dutch')}>Dutch</a>
        //                         </li>
        //                     )}
        //                     {/* <li className="switcher-option">
        //                         <button onClick={ onStoreSwitcherClick }>Other Languages</button>
        //                     </li> */}
        //                 </ul>
        //             </div>
        //             {this.renderSipingCont()}
        //         </div>
        //     );
        // }
        // if (isUsaStore()) {
        //     return (
        //         <div className="switcher-language-wrap">
        //             <div className="switcher-language">
        //                 <div className="switcher-trigger">
        //                     {codeName}
        //                 </div>
        //                 <ul>
        //                     {(codeName !== 'English') && (
        //                         <li className="switcher-option">
        //                             <a href={this.generateCurrentRedirectUrl('en', 'us')} onClick={() => this.updateName('English')}>English</a>
        //                         </li>
        //                     )}
        //                     {(codeName !== 'Spanish') && (
        //                         <li className="switcher-option">
        //                             <a href={this.generateCurrentRedirectUrl('es', 'us')} onClick={() => this.updateName('Spanish')}>Spanish</a>
        //                         </li>
        //                     )}
        //                 </ul>
        //             </div>
        //             {this.renderSipingCont()}
        //         </div>
        //     );
        // }

        return (
            <div className="switcher-language-wrap">
                { this.renderShippingText() }
                { this.renderBNPLOptions() }
                { this.renderTrustedShopsBadge() }
            </div>
        );
    }

    renderDometicLogo() {
        return (
            <div className="dometicLogo">
                <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 20">
                <path d="M18.975 4.114c0-.297.118-.582.328-.792L22.47.154l10.08 10.078-9.287 9.286a1.12 1.12 0 01-1.583 0l-3.168-3.167 6.119-6.119-5.328-5.326a1.12 1.12 0 01-.328-.792z" fill="currentColor" />
                <path fillRule="evenodd" clipRule="evenodd" d="M63.394 1.05c-4.996 0-9.046 4.111-9.046 9.182 0 5.07 4.05 9.182 9.046 9.182 4.996 0 9.047-4.11 9.047-9.182 0-5.071-4.05-9.182-9.047-9.182zm0 13.796c-2.469 0-4.477-2.07-4.477-4.614s2.008-4.613 4.477-4.613c2.47 0 4.478 2.07 4.478 4.613 0 2.544-2.008 4.614-4.478 4.614zM38.148 1.274a1.12 1.12 0 00-1.12 1.12V19.19h7.092a8.959 8.959 0 000-17.917h-5.972zm3.359 13.437V5.754h2.613c2.47 0 4.345 2.01 4.345 4.479 0 2.47-1.875 4.479-4.345 4.479h-2.613z" fill="currentColor" />
                <path d="M88.776 1.274a1.12 1.12 0 00-1.055.747l-3.798 10.077-3.797-10.077a1.12 1.12 0 00-1.055-.746l-5.225-.001V19.19h4.479V8.739l4.106 10.318h2.986L89.523 8.74v10.45h4.48V1.274h-5.227zM131.329 19.19h-4.852V1.274h4.852V19.19zM96.242 1.274V18.07a1.122 1.122 0 001.12 1.12h11.57v-4.48h-8.211v-2.986h6.719V7.993h-6.719v-2.24h8.211V1.275h-12.69zM110.426 4.634v-3.36h14.557v4.48h-4.852V19.19h-4.852V5.752h-3.734a1.12 1.12 0 01-1.119-1.12zM19.519 9.44L10.232.155.154 10.233 3.323 13.4a1.12 1.12 0 001.584 0l5.325-5.328 6.12 6.12 3.166-3.169a1.119 1.119 0 000-1.582zM143.652 6.042a4.412 4.412 0 00-1.871-.423c-2.47 0-4.478 2.07-4.478 4.614 0 2.543 2.008 4.613 4.478 4.613a4.414 4.414 0 003.386-1.6l3.238 3.237a8.954 8.954 0 01-6.624 2.931c-4.996 0-9.047-4.111-9.047-9.182 0-5.071 4.05-9.182 9.047-9.182a8.956 8.956 0 016.624 2.931l-3.238 3.238c-.413-.5-.93-.901-1.515-1.177z" fill="currentColor" />
                </svg>
            </div>
        );
    }

    renderTrustedShopsBadge() {
        const storeCode = window?.storeConfig?.code ?? null;
        if (!storeCode) {
            return null;
        }

        const trustedShopsImg = 'https://www.frontrunneroutfitters.com/media/trustedshops/logo.png';
        let link = null;

        if (storeCode === 'en_de') {
            link = 'https://www.trustedshops.eu/buyerrating/info_X29D0E1C335EB7B6107E2A578BA7CE7CF.html';
        }
        if (storeCode === 'de_de') {
            link = 'https://www.trustedshops.de/bewertung/info_X731D943B3AF57D3643139AEBECEF2568.html';
        }

        if (!link) {
            return null;
        }

        return (
            <div className="switcher-language-wrap-item trusted-shops">
                <a href={link} target="_blank" rel="noreferrer nofollow">
                    <img src={trustedShopsImg} alt="Front Runner Reviews on Trusted Shops" border="0" width={ 18 } height={ 18 } fetchpriority="high" />
                    { ' ' }
                    Trustedshops
                </a>
            </div>
        );
    }

    renderBNPLOptions() {
        // Check if B2B customer (Use local storage check)
        const isB2B = JSON.parse(sessionStorage.getItem('is_b2b')) ?? false;
        if (isB2B) {
            return null;
        }

        // TODO: Switch on when other regions are set up correctly.
        if (isUsaStore() || isRsaStore()) {
            const { apr_upto_months } = this.props;

            if (isEmpty(apr_upto_months)) {
                return null;
            }

            let className = 'switcher-language-wrap-item bnplTxt';

            // USA
            if (isUsaStore()) {
                className = 'switcher-language-wrap-item bnplTxt aprUptoTxt';

                return (
                    <div className={ className } dangerouslySetInnerHTML={{ __html: apr_upto_months }} />
                );
            }

            // Other regions
            return (
                <div className={ className }>
                    <FontAwesomeIcon icon={ faThumbsUp } size="1x" color="#fff" />
                    <div className="bnplTxt-Container" dangerouslySetInnerHTML={{ __html: apr_upto_months }} />
                </div>
            );
        }

        return null;
    }

    renderShippingText() {
        const { is_free_shipping_enable, freeShippingHeaderText = {}, free_shipping_header_text_b2b = {} } = this.props;
        const { customer = {} } = this.props;
        const { is_b2b } = customer;
        if (isEmpty(free_shipping_header_text_b2b)) {
            return null;
        }
        if (is_free_shipping_enable === '1' && (isEmpty(is_b2b) || is_b2b == 'false' || is_b2b == false)) {
            return (
                <div className="switcher-language-wrap-item free_shiping">
                    <Link
                      to="/shipping-policies"
                      block="shiping"
                      elem="link"
                    >
                        <FontAwesomeIcon icon={ faShippingFast } size="1x" color="#fff" />
                        { ' ' }
                        { freeShippingHeaderText }
                    </Link>
                </div>
            );
        }
        if (is_free_shipping_enable === '1' && is_b2b) {
            return (
                <div className="switcher-language-wrap-item free_shiping">
                    <Link
                      to="/shipping-policies"
                      block="shiping"
                      elem="link"
                    >
                        <FontAwesomeIcon icon={ faShippingFast } size="1x" color="#fff" />
                        { ' ' }
                        {free_shipping_header_text_b2b}
                    </Link>
                </div>
            );
        }

        return null;
    }

    renderTopBanner() {
        const { customer = {} } = this.props;
        if (isEmpty(customer)) {
            return null;
        }

        return <TopBannerContainer customer={customer} />;
    }

    /** **** Vehicle Garage********* */
    renderVehicleGarageIcon() {
        const {
            onVehicleGarageButtonClick,
            shouldRenderGarageOverlay
        } = this.props;

        let active = '';
        if (shouldRenderGarageOverlay) {
            active = 'activeVeh';
        }

        return (
            <button
              block="Header"
              elem={`vehGarg ${active}`}
              tabIndex="0"
              aria-label={ __('My Vehicle Garage') }
              onClick={ onVehicleGarageButtonClick }
            >
              <FontAwesomeIcon icon={ faCarGarage } />
              {/* { this.renderMinicartItemsQty() } */}
            </button>
        );
    }

    renderMinicartItemsQty() {
        const { cartTotals: { items_qty } } = this.props;

        if (!items_qty) {
            return null;
        }

        return (
            <span
              aria-label="Items in cart"
              block="Header"
              elem="MinicartItemCount"
            >
                { items_qty }
            </span>
        );
    }

    renderMinicartButton() {
        const {
            onMinicartButtonClick,
            device: { isMobile }
        } = this.props;

        if (isMobile) {
            return (
                <button
                  block="Header"
                  elem="MinicartButtonWrapper"
                  tabIndex="0"
                  aria-label={ __('Checkout') }
                  onClick={ onMinicartButtonClick }
                >
                    <CartIcon />
                    { this.renderMinicartItemsQty() }
                </button>
            );
        }

        return (
            <button
              block="Header"
              elem="MinicartButtonWrapper"
              tabIndex="0"
              aria-label={ __('Checkout') }
              onClick={ onMinicartButtonClick }
            >
                <FontAwesomeIcon icon={ faCartShopping } />
                { this.renderMinicartItemsQty() }
            </button>
        );
    }

    renderMinicart(isVisible = false) {
        const {
            onMinicartOutsideClick,
            isCheckout,
            device: { isMobile }
        } = this.props;

        if (isCheckout && !isMobile) {
            return null;
        }

        return (
            <ClickOutside
              onClick={ onMinicartOutsideClick }
              key="minicart"
            >
                <div
                  block="Header"
                  elem="Button"
                  mods={ { isVisible, type: 'minicart' } }
                >
                    { this.renderMinicartButton() }
                    { this.renderMinicartOverlay() }
                </div>
            </ClickOutside>
        );
    }

    renderVehicleGarageOverlay() {
        const { shouldRenderGarageOverlay, onVehicleGarageButtonClick } = this.props;
        if (!shouldRenderGarageOverlay) {
            return null;
        }

        return (
            <div className="vehicleGaragePop" ref={this.ref}>
                <div className="vehicleGaragePopTitle">{__('My Vehicle Garage')}</div>
                 <CustomerGarageHeader onVehicleGarageButtonClick={ onVehicleGarageButtonClick } />
            </div>
        );
    }

    renderVehicleGarage(isVisible = true) {
        const {
            onMinicartOutsideClick,
            isCheckout,
            device: { isMobile }
        } = this.props;

        if (isMobile || isCheckout) {
            return null;
        }

        return (
            <div
              block="Header"
              elem="vehGarg vehicleGarage"
              mods={ { isVisible, type: 'VehicleGarage' } }
            >
                { this.renderVehicleGarageIcon() }
                { this.renderVehicleGarageOverlay() }
            </div>
        );
    }
    /** **** Vehicle Garage End********* */

    getNoticeCookie=() => {
        const c_name = this.noticeCookies();
        let c_start = cookies.get(c_name);
        if (c_start) {
            //
        } else {
            c_start = null;
        }

        return c_start;
    };

    noticeCookies= () => {
        const noticeCookieName = `${window.storeConfig.baseName }notice_key`;

        return noticeCookieName;
    };

    renderNoticeText(text) {
        return (
            <Html content={ text } />
        );
    }

    renderTopMobileMenu() {
        const {
            device
        } = this.props;
        const baseUrl = window.location.pathname.length;

        // if (!device.isMobile || baseUrl > 7) {
        if (!device.isMobile) {
            return null;
        }

        return (
            <div className="topMobileMenu">
                { this.renderShippingText() }
                {/* {this.renderDometicLogo()}
                { this.renderAccountMobileButton() } */}
            </div>
        );
    }

    closeNoticeView=() => {
        const { onHeaderMenuClick } = this.props;
        const now = new Date();
        now.setTime(now.getTime() + 7 * 24 * 3600 * 1000);
        const cname = this.noticeCookies();
        cookies.set(cname, 'pre', { path: '/', expires: now });
        // this.setState({ noticeView: false });
        onHeaderMenuClick();
    };

    isNoticeAllowed=() => {
        const { headerNotifications = {}, noticeView = true } = this.props;
        const {
            enable = '0',
            dateFrom = '',
            dateTo = ''
        } = headerNotifications;

        if (enable === '0') {
            return false;
        }

        if (!isEmpty(dateFrom) && !isEmpty(dateTo)) {
            // Date format
            const dateFormat = 'yyyy-MM-dd HH:mm:ss';

            let startDate = DateTime.fromISO(dateFrom, { zone: 'utc' });
            startDate = startDate.set({ hour: 0, minute: 0, second: 1 }).toFormat(dateFormat);

            let endDate = DateTime.fromISO(dateTo, { zone: 'utc' });
            endDate = endDate.set({ hour: 23, minute: 59, second: 59 }).toFormat(dateFormat);

            let timezone = 'UTC';
            if (isRsaStore()) {
                timezone = 'Africa/Johannesburg';
            }

            if (isUsaStore()) {
                timezone = 'America/New_York';
            }

            if (isEurStore()) {
                timezone = 'Europe/Berlin';
            }

            if (isAusStore()) {
                timezone = 'Australia/Sydney';
            }

            const timeNow = DateTime.now().setZone(timezone).toFormat(dateFormat);
            if (timeNow < startDate || timeNow > endDate) {
                return false;
            }

            return true;
        }

        return true;
    };

    renderNoticeRow = () => {
        // If checkout page, don't show red bar
        if (window.location.href.indexOf('/checkout/') > -1) {
            return null;
        }

        const { headerNotifications = {}, noticeView = true } = this.props;
        const {
            enable = '0',
            text = null,
            buttonText = '',
            ctaButtonText = '',
            ctaButtonLink = '',
            dateFrom = '',
            dateTo = ''
        } = headerNotifications;
        const visit = this.getNoticeCookie();
        if (visit === 'pre' || noticeView === false || text === null) {
            return null;
        }

        // Check if B2B customer (Use local storage check)
        // const isB2B = JSON.parse(sessionStorage.getItem('is_b2b')) ?? false;

        // If dates are not empty, then check if current date is within range
        if (!this.isNoticeAllowed()) {
            return null;
        }

        // Ensure ctaButtonLink always starts with a /
        let ctaButtonLinkValidation = ctaButtonLink;
        if (!isEmpty(ctaButtonLinkValidation)) {
            ctaButtonLinkValidation = ctaButtonLinkValidation.startsWith('/') ? ctaButtonLinkValidation : `/${ctaButtonLink}`;
        }

        return (
            <div className="notice-row">
                <div className="notice-row-wrap">
                    <label
                      htmlFor="CheckoutBilling"
                      block="CheckoutBilling"
                      elem="Link"
                    >
                        { this.renderNoticeText(text) }
                    </label>

                    {/* If ctaButtonText and ctaButtonLink is set, show link */}
                    { ctaButtonText && ctaButtonLink && (ctaButtonLink !== 'newsletter') && (
                        <Link to={ctaButtonLinkValidation}>
                            { ctaButtonText }
                        </Link>
                    )}

                    {/* If ctaButtonLink = newsletter, then scroll to newsletter section */}
                    { ctaButtonText && (ctaButtonLink === 'newsletter') && (
                        <Link
                          to={ctaButtonLink}
                          onClick={ (e) => {
                              e.preventDefault();
                              const element = document.getElementById('footer_newsletter');
                              element.scrollIntoView({ behavior: 'smooth' });
                          } }
                        >
                            { ctaButtonText }
                        </Link>
                    ) }

                    <button className="nt_btn" onClick={ () => this.closeNoticeView() }>
                        { buttonText }
                        <CloseIcon />
                    </button>
                </div>
            </div>
        );
    };

    render() {
        // TODO: @Ajeet @Yash investigate why this component being rendered multiple times
        // console.log('render hit');

        const { stateMap } = this;
        const { noticeView = true, isOpen } = this.state;
        const {
            navigationState: { name, isHiddenOnMobile = false },
            isCheckout,
            device,
            headerNotifications = {},
            headerOverMenuClick,
            onHeaderOverMenuClick
        } = this.props;
        const { enable = '1', text = null } = headerNotifications;
        if (!device.isMobile) {
            // hide edit button on desktop
            stateMap[CUSTOMER_WISHLIST].edit = false;
            stateMap[CUSTOMER_WISHLIST].share = false;
            stateMap[CART_OVERLAY].edit = false;
        }
        const visit = this.getNoticeCookie();
        let notcText = 'noticActive';
        if (visit === 'pre' || noticeView === false || text === null || enable === '0' || enable === null || !this.isNoticeAllowed()) {
            notcText = '';
        }

        const bsUrl = window.location.pathname;
        let pageName = '';
        if (bsUrl.includes('/vehicle/')) {
            pageName = 'vehicle';
        }

        return (
            <>
            <section block="Header" elem={ `Wrapper ${notcText}` } key="header-wrapper">
                <header
                  block="Header"
                  mods={{ name, isHiddenOnMobile, isCheckout }}
                  mix={{ block: 'FixedElement', elem: 'Top' }}
                  ref={this.logoRef}
                >
                    { (enable === '1') && this.renderNoticeRow() }
                    {this.renderTopMenu()}
                    {this.renderTopMobileMenu()}
                    {this.renderTopBanner()}
                    <div className={ `top_nav ${pageName}`} key="top-nav">
                        <nav block="Header" elem="Nav">
                            {!device.isMobile && !isCheckout && this.renderDometicLogo()}
                            {this.renderLanguageSw()}
                            {this.renderNavigationState()}
                        </nav>
                    </div>
                    {this.renderMenu()}
                </header>
                <OfflineNotice key="offline-notice" />
            </section>
                {headerOverMenuClick && (
                    <div className="menuOverlayHeader" key="menuOverlay">
                        <div className="menuOverlayHeaderInner">
                            <button onClick={this.menuMobileOverlay} className="closeBtn">
                                Close
                                <CloseIcon />
                            </button>
                            <MenuOverlay onHeaderOverMenuClick={onHeaderOverMenuClick} />
                            {this.renderStoreSwitcherOver()}
                            {this.renderCloseButton(true)}
                        </div>
                    </div>
                )}
            </>
        );
    }
}
export default HeaderComponent;
