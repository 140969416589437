/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable react/no-danger */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable no-undef */
/* eslint-disable prefer-const */
/* eslint-disable fp/no-let */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable react/sort-comp */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */

import { Component } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';

import Link from 'Component/Link';

import { isAusStore, isEurStore, isRsaStore } from '../../util/FrontRunner/Store';

import './Faq.style.scss';
/** @namespace Pwa/Component/YotpoLoyalty/Component/YotpoLoyaltyComponent */
export class YotpoLoyalty extends Component {
    getItemState({ expanded }) {
        return (
            <div className="toggle-nav">
            { expanded ? '-' : '+' }
            </div>
        );
    }

    renderEmailAddress() {
        let emailAddress = 'info@frontrunneroutfitters.com';
        if (isRsaStore()) {
            emailAddress = 'info@frontrunner.co.za';
        }
        if (isAusStore()) {
            emailAddress = 'info@frontrunneroutfitters.com.au';
        }
        if (isEurStore()) {
            emailAddress = 'info.eu@frontrunneroutfitters.com';
        }

        return (
            <a href={ `mailto:${emailAddress}` }>
                { emailAddress }
            </a>
        );
    }

    renderFAQAccordions() {
        let currencyCode = 'USD';
        let currencySymbol = '$';
        let vip1total = 0;
        let vip2total = 1500;
        let vip3total = 3000;

        let pointsMin = 400;

        if (isRsaStore()) {
            currencyCode = 'ZAR';
            currencySymbol = 'R';
            vip1total = 0;
            vip2total = 15000;
            vip3total = 30000;
        }

        if (isAusStore()) {
            currencyCode = 'AUD';
            currencySymbol = '$';
        }

        // Format the totals
        vip1total = currencySymbol + vip1total;
        vip2total = currencySymbol + vip2total;
        vip3total = currencySymbol + vip3total;

        return (
          <div className="faqAccordionWrapper">
            <h2>Frequently Asked Questions</h2>
            <Accordion allowZeroExpanded>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Signing Up
                            <AccordionItemState>
                                { this.getItemState }
                            </AccordionItemState>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <h4>How do I sign up for the Front Runner Explorer Rewards program?</h4>
                        <p>
                            Signing up is easy! Simply create an account
                            { ' ' }
                            <Link to="/login" target="_blank" rel="noopener">here</Link>
                            { ' ' }
                            and enter your name, email, and password. Ensure that the checkbox
                            is selected to join the Explorer Rewards program. It's free to join and you will automatically be
                            signed up to receive emails with insider access to sales, promotions, and all of our newest drops.
                            If you currently have an account or email subscription with us - you've already been enrolled in
                            our rewards program so you can kick back, sign in, and start earning Points!
                        </p>

                        <h4>What are the advantages of becoming an Explorer Rewards member?</h4>
                        <p>
                            The Explorer Rewards program is a tiered program that allows members to earn more, the more
                            they engage with us. Members receive reward points for each purchase, which can then be
                            redeemed towards the cost of future purchases.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Account
                            <AccordionItemState>
                                { this.getItemState }
                            </AccordionItemState>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <h4>I already have a Front Runner account. Do I need to create a new one to earn rewards?</h4>
                        <p>
                            We took care of that for you! If you have an active account with us, you were automatically
                            enrolled in our rewards program. If you have had more than one customer account with us,
                            please note that only one rewards account was created per person.
                        </p>
                        <h4>Where can I find all of my rewards account info?</h4>
                        <p>
                            The Explorer Rewards page is your roadmap to all your points and perks. You can access all
                            your info by visiting our
                            { ' ' }
                            <Link to="/rewards" target="_blank" rel="noopener">rewards page</Link>
                            { ' ' }
                            .
                        </p>
                        <h4>Will I receive points for purchases I made prior to creating my rewards account?</h4>
                        <p>
                            You will only receive points for purchases made after creating your rewards account. Make sure
                            to create an account before placing an order so you can receive points!
                        </p>
                        <h4>
                            What if I have an old email address or multiple email addresses and want to combine my
                            accounts &amp; earnings?
                        </h4>
                        <p>
                            Contact customer service by emailing { this.renderEmailAddress() } and our team will be happy
                            to assist you with merging your accounts.
                        </p>
                        <h4>Does it cost anything to begin earning points?</h4>
                        <p>
                            Absolutely not! Sign-up is 100% free, and it will never cost you anything to earn points. Make
                            sure to visit the
                            { ' ' }
                            <Link to="/rewards" target="_blank" rel="noopener">rewards page</Link>
                            { ' ' }
                            to get started.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                             Earning &amp; Redemption
                            <AccordionItemState>
                                { this.getItemState }
                            </AccordionItemState>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <h4>How do I redeem my points?</h4>
                        <p>
                        Exchanging your points for great rewards couldn&#39;t be easier! Simply visit the
                        { ' ' }
                        <Link to="/rewards" target="_blank" rel="noopener">rewards page</Link>
                        { ' ' }
                        to view all of our great reward options and click the &#39;Redeem&#39;
                        button to redeem your chosen reward. However, loyalty discounts and any other coupon codes
                        cannot be used in conjunction at the same time. Loyalty discounts cannot redeemed against
                        purchases during promotional events.
                        </p>
                        <h4>How do points convert to { currencyCode }?</h4>
                        <p>
                        Higher tiers in the VIP tier program earn more points at a quicker rate, but 20 points always = 1 { currencyCode }.
                        <br />
                        For example, as an Explorer you will earn 1 point for every 1 { currencyCode } you spend.
                        <br />
                        When you move up to become a Pioneer you will be earning points 1.25x as fast.
                        <br />
                        Moving up to become a Trailblazer you will be earning points 1.5x as fast.
                        </p>
                        <h4>What are the easiest ways to earn points?</h4>
                        <p>
                        For starters- each member gets a reward just for joining the Explorer Rewards program. Aside
                        from earning points through purchases, you can add your birthday to your account to earn points,
                        leave us a review, or like us on Instagram. Access all the ways to earn under the ways to earn
                        points on the
                        { ' ' }
                        <Link to="/rewards" target="_blank" rel="noopener">rewards page</Link>
                        { ' ' }
                        .
                        </p>
                        <h4>Will I be able to use my points/rewards in-store?</h4>
                        <p>
                        You will not be able to earn or apply rewards points towards purchases in-store at this time.
                        We are working to link our stores with our online site in future.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Point Limitations
                            <AccordionItemState>
                                { this.getItemState }
                            </AccordionItemState>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <h4>Is there a limit to how many points I can earn?</h4>
                        <p>
                            Nope! That's the best part about being a rewards member! You will always have an opportunity
                            to earn more points by shopping with us, engaging with our brand and community online.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Returns
                            <AccordionItemState>
                                { this.getItemState }
                            </AccordionItemState>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <h4>Can I return my order if I redeemed rewards points for a discount?</h4>
                        <p>
                        Yes. If you are returning the entire order, we will refund you the total dollar amount spent after
                        the discount was applied. The points you used to redeem the discount will also be added back to
                        your rewards account.
                        </p>
                        <h4>
                            I earned points/VIP tier with my last purchase, but now I want to return it. What will happen to my reward?
                        </h4>
                        { isRsaStore() && (
                            <p>
                            If you use your rewards towards a purchase and later decide to return that item, your rewards will
                            be credited back to your rewards account. For example, if you use a R400 coupon towards a R1000
                            purchase that you decide to return, the R600 balance will be refunded to you and the R400 reward
                            credit will be deposited back into your rewards account.
                            </p>
                        ) } { !isRsaStore() && (
                            <p>
                            If you use your rewards towards a purchase and later decide to return that item, your rewards will
                            be credited back to your rewards account. For example, if you use a $20 coupon towards a $100
                            purchase that you decide to return, the $80 balance will be refunded to you and the $20 reward
                            credit will be deposited back into your rewards account.
                            </p>
                        ) }

                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Reward Expiration
                            <AccordionItemState>
                                { this.getItemState }
                            </AccordionItemState>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <h4>Do points ever expire?</h4>
                        <p>
                        Points and rewards and your VIP tier will expire if you fail to make at least 1 purchase every 12
                        months. If we ever decide to end the Explorer Rewards program, your points and perks will
                        expire with the program. We also reserve the right to terminate any person's rewards
                        membership if we believe you are violating our
                        { ' ' }
                        <Link to="/terms-of-use" target="_blank" rel="noopener">Terms of Use</Link>
                        { ' ' }
                        , reselling products, exploiting the program, making excessive
                        returns, engaging in fraud, abuse of privileges, or inappropriate behavior that seeks to
                        manipulate the rewards program, or for any other reason we deem necessary based on our sole
                        discretion. In the event your account is terminated by you or us, or you opt out of the rewards
                        program, you will lose any unused rewards as well as all points that have not been redeemed.
                        </p>
                        <p>
                        Rewards points are valid for the remainder of the calendar year in which they are earned, plus
                        the following calendar year.
                        </p>
                        <p>
                            For example: points earned anytime during the year 2024 are valid for the remainder of 2024,
                            plus all of 2025. Points will start over at 0 on January 1, 2026.
                        </p>
                        <p>
                            Rewards points will expire after 12 months of Explorer Rewards account inactivity. This means
                            that if there is no change to your Rewards account balance (through either the addition or
                            redemption of Rewards), all Rewards earned in the previous 12 months will automatically be
                            removed from your account.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            VIP Program
                            <AccordionItemState>
                                { this.getItemState }
                            </AccordionItemState>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <h4>How is my VIP tier determined?</h4>
                        <p>
                        Your VIP tier is determined by the amount you spend in a calendar year (for example, Jan 1, 2024 - Dec
                        31, 2024).
                        <br />
                        To reach:
                        <br />
                        Explorer, you must spend { vip1total }
                        <br />
                        Pioneer, you must spend { vip2total }+
                        <br />
                        Trailblazer, you must spend { vip3total }+
                        </p>
                        <p>
                        Once you qualify, you will instantly move up and have access to that tier's perks through the rest
                        of the current calendar year as well as the following calendar year. As you move up in the VIP
                        Tiers, you will retain all benefits from your previous tier.
                        </p>
                        <h4>How do I know which Rewards tier I am currently a member of?</h4>
                        <p>
                        When you are signed into your rewards account, your home page will list all of the rewards tiers.
                        Your current tier and perks will be highlighted with a box around it.
                        </p>
                        <h4>How will I know when I have reached a new tier?</h4>
                        <p>
                        When your total points cross a tier threshold you will receive an email welcoming you into the
                        new tier, for example, Explorer, Pioneer or Trailblazer.
                        </p>
                        <h4>Tier Expiration</h4>
                        <p>
                        Once you are in an Explorer, Pioneer or Trailblazer tier, you will be in that tier for one year from the date
                        you earned it. Make sure to keep interacting with our program to maintain your status (or even
                        move up a tier)!
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Troubleshooting
                            <AccordionItemState>
                                { this.getItemState }
                            </AccordionItemState>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <h4>Why didn't I earn points on my purchase?</h4>
                        <p>
                        Double-check you were signed into your Explorer Rewards account and didn't accidentally check
                        out as a guest. You must be logged in when making purchases to earn points. If you have done
                        this in error, contact us at { this.renderEmailAddress() } to help apply your points to your
                        account. You also won't receive points if you return your purchase or your purchase is cancelled
                        for any reason. Please note that any points you earn on a purchase you later return will be
                        deducted from your point balance.
                        </p>
                        <h4>
                        I should have earned points for a purchase/incentive but I didn't see my account balance
                        change. Did I get credit?
                        </h4>
                        <p>
                        Yes! You work hard to earn points and we want you to feel confident that all of your points are
                        right where they should be... in your account! The first thing to note is that there is a delay of 30
                        days for purchase points to appear in your account and serval hours for incentive points to be
                        allocated. However, if you feel like you have earned points but they are not showing up in your
                        account you can also troubleshoot it with these tricks:
                        </p>
                        <p>
                        1. Try to refresh your browser.
                        { ' ' }
                        <br />
                        2. Try to log out and then back in to your account.
                        { ' ' }
                        <br />
                        3. Check your 'Rewards history' in your Rewards account to see if the points were added
                        without you realizing it. Your 'Rewards history' will show you the number of points
                        earned, how and when they were earned, as well as any redemptions of points made on
                        your account.
                        </p>
                        <p>
                        If you still feel like your points balance is not accurate you can contact our customer support
                        team at { this.renderEmailAddress() } and they will look into the issue for you.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Birthday Reward
                            <AccordionItemState>
                                { this.getItemState }
                            </AccordionItemState>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <h4>How can I get a birthday reward?</h4>
                        <p>
                        Add your birthday under your account details and on your birthday we will update your account
                        with points.
                        </p>
                        <h4>I signed up for the Birthday reward but I didn't receive it.</h4>
                        <p>
                            Happy Birthday! We're so glad that you have chosen to celebrate with us through our rewards
                            program. In order for you to receive your birthday points on your birthday, you must register your
                            birthday at least 30 days prior to your birthday.
                        </p>
                        <p>
                        If your birthday falls between the day that you registered and the 30-day waiting period afterward,
                        you will receive your birthday bonus 30 days after your registration. This delay is only applicable
                        for the first year that you signed up.
                        </p>
                        <p>
                        All successive birthday points will be emailed to you on your birthday. If you register your
                        birthday through your rewards account any time after it has passed in the current calendar year,
                        points will not be added to your account until the day of your birthday in the following year.
                        </p>
                        <p>
                        Remember, birthday points are determined by the Rewards member tier that you are part of on
                        the date of your birthday. If it has been more than 30 days since you registered for your birthday
                        reward and you still have not received the additional points in your account, please contact our
                        customer support team at { this.renderEmailAddress() } and we will be happy to assist you.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Checkout
                            <AccordionItemState>
                                { this.getItemState }
                            </AccordionItemState>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <h4>How do I redeem my rewards points?</h4>
                        <p>
                            Rewards points can be redeemed during the checkout process. Before checking out, ensure that
                            you are signed in to your account.
                        </p>
                        <p>
                            Once you get to the billing screen you will see your total available Rewards points in a slide
                            bar on the top left of the page. To use your rewards, slide the points redemption bar to select the
                            number of points you would like to redeem on the order you are placing, then continue with the
                            checkout process. To redeem all available Rewards slide the bar all the way to the right.
                        </p>
                        <p>
                            You must have a minimum balance of at least 400+ points each time you would like to make a
                            redemption, and points must be redeemed in increments of 400.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
          </div>
        );
    }

    renderFAQAccordionsENDE() {
        return (
            <div className="faqAccordionWrapper">
                <h2>Terms and Condition</h2>
                <Accordion allowZeroExpanded>
                    { /* Registration Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Registration
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>How do I sign up for the Front Runner Explorer Rewards Program?</h4>
                            <p>
                                Signing up is easy: Create your customer account on our Front Runner website by entering your
                                name, email address, and password. If you already have a customer account, you can proceed
                                directly to the next step.
                            </p>
                            <p>
                                On the Front Runner website, go to “Rewards” in your account settings and click the box
                                “I would like to participate in the free Explorer Rewards loyalty program and agree to the Terms
                                and Conditions and Privacy Policy.” to join the Explorer Rewards Program. You can verify your
                                registration through a confirmation email. Membership is free of charge.
                            </p>

                            <h4>What are the benefits of the Explorer Rewards Program?</h4>
                            <p>
                                The Explorer Rewards Program is tiered: the more you purchase and interact with us, the more
                                loyalty points you accumulate. You can redeem these points on future purchases.
                            </p>
                            <p>
                                You can earn points by following us on social media, writing product reviews, adding photo or
                                video reviews, and having your birthday! From time to time, we'll add new rewards and benefits
                                to the Explorer Rewards Program.
                            </p>
                            <p>You can find all the benefits in the graphic overview above.</p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Customer Account Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Customer Account
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>I already have a Front Runner customer account – do I need to create a new one to collect points?</h4>
                            <p>
                                No, you don't! If you already have an active account, follow these steps: Go to “Rewards” in
                                your account and check the box “I would like to participate in the free Explorer Rewards
                                Program and agree to the Terms and Conditions and Privacy Policy.” to join the Explorer
                                Rewards Program. You can verify your registration through a confirmation email.
                            </p>
                            <p>
                                Important: If you have multiple accounts, you can only collect points on one account. Points
                                are only collected in the customer account you use to sign up for the loyalty program. Points
                                cannot be transferred to another customer account.
                            </p>

                            <h4>How can I unsubscribe from the Explorer Rewards Program?</h4>
                            <p>
                                If you want to unsubscribe from Explorer Rewards, simply uncheck the box under “Rewards” and
                                confirm unsubscribing through the confirmation email. Your unused points will then expire, and
                                you'll also be automatically unsubscribed from the personalized loyalty program newsletter.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Earning and Redeeming Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Earning and Redeeming
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>How can I earn Explorer Rewards points?</h4>
                            <p>Explorer Rewards points can only be collected in our online shop.</p>

                            <h4>How do I redeem my points?</h4>
                            <p>
                                Redeeming your points for discounts, or rewards, is easy! You'll find all available options on our
                                website. Once you have reached 400 points, you can redeem your rewards with a minimum
                                purchase value of 40 Euro at checkout. Choose how many points you want to redeem for your
                                purchase and click “UPDATE”. Please note that discounts and other promo codes cannot be
                                combined. Points cannot be redeemed during promotions.
                            </p>
                            <p>
                                <ul>
                                    <li>20 Euro off at 400 points for a minimum purchase of 40 Euro</li>
                                    <li>40 Euro off at 800 points for a minimum purchase of 80 Euro</li>
                                    <li>60 Euro off at 1200 points for a minimum purchase of 120 Euro</li>
                                    <li>80 Euro off at 1600 points for a minimum purchase of 160 Euro</li>
                                    <li>100 Euro off at 2000 points for a minimum purchase of 200 Euro</li>
                                </ul>
                            </p>
                            <p>A maximum of 100 Euro can be redeemed per purchase using Explorer Rewards points.</p>

                            <h4>How many points do I earn per Euro spent?</h4>
                            <p>
                                The Explorer Rewards Program has various levels where you can earn different amounts of points:
                                <ul>
                                    <li>As an Explorer, you earn 1 point for every Euro spent (1 Euro = 1 point).</li>
                                    <li>As a Pioneer, you earn 1.25 times more points per Euro (1 Euro = 1.25 points).</li>
                                    <li>As a Trailblazer, you earn 1.5 times more points per Euro (1 Euro = 1.5 points).</li>
                                </ul>
                            </p>

                            <h4>How quickly can I earn points?</h4>
                            <p>
                                You'll already earn a reward when you join the Explorer Rewards Program. Points can also be
                                earned through purchases, on your birthday, by leaving a review, liking on Instagram, and more.
                                On the Explorer Rewards page, you'll find a full overview of all ways to earn points.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Returns Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Returns
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>What happens to my points if I return part of my order?</h4>
                            <p>
                                If you purchase for 1,000 Euro but return items worth 900 Euro, you will only earn points for
                                the remaining amount (100 Euro). Please note that the return period is 14 days, and points
                                will be credited to your account only after this period.
                            </p>

                            <h4>Can I return my order if I redeemed Explorer Rewards Program points for a discount?</h4>
                            <p>
                                Yes, this is possible. For a full return, you will be refunded the amount paid after the discount,
                                and the redeemed points will be credited back to your Explorer Rewards points balance.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Points Expiration Date Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Points Expiration Date
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Can earned points expire?</h4>
                            <p>
                                Yes, points and VIP levels expire if you don't make a purchase within 12 months. Additionally,
                                all points expire if you end your membership. Points are valid until the end of the calendar year
                                in which you earned them and for the entire following year. For example, points earned in 2024
                                are valid until the end of 2025. If you are inactive for 12 months, all unused points will expire.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Troubleshooting Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Troubleshooting
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Why haven't I received points for my purchase?</h4>
                            <p>
                                Make sure you were logged into your customer account when you made the purchase and didn't accidentally check out as a guest. To collect points, you must be logged into your customer
                                account when you purchase. If you skipped this step by mistake, please contact us at <a href="mailto:info.eu@frontrunneroutfitters.com">info.eu@frontrunneroutfitters.com</a>, and we'll add the points to your account.
                            </p>
                            <p>
                                Please note that you will not receive additional points for your loyalty program account if you return or cancel your order for any reason. New loyalty points are only awarded for fully
                                completed orders. In case of a return, the points already used for the returned order will be credited back to your account.
                            </p>

                            <h4>I should have received points for a purchase, but my balance hasn't changed. Were the points credited to me?</h4>
                            <p>
                                Yes! You worked hard for your points, and we want you to be sure they are credited correctly to your account. Please note that it can take up to 15 days for points to be credited to your
                                account after a purchase, and then a few hours before you can redeem them for a discount. If you still feel that points are missing, try the following:
                            </p>
                            <ol>
                                <li>Refresh your browser.</li>
                                <li>Log out of your account and log back in.</li>
                                <li>Check the “Reward History” section in your account. Here you can see how many points you have earned, when and for what they were credited, as well as all your points redemptions.
                                </li>
                            </ol>
                            <p>
                                If you still feel that your point balance is incorrect, please contact our team at <a href="mailto:info.eu@frontrunneroutfitters.com">info.eu@frontrunneroutfitters.com</a>. We will be happy to check the issue for you.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Birthday Points Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Birthday Points
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>How do I get my birthday points?</h4>
                            <p>
                                Enter your date of birth in your customer account. Loyalty points will automatically be credited to you on your birthday.
                            </p>

                            <h4>I signed up for birthday points but didn't receive them.</h4>
                            <p>
                                Happy Birthday! We're excited you chose to celebrate with us through our loyalty program. To receive your birthday points, you must enter your date of birth in your account at least 30 days before your birthday.
                            </p>
                            <p>
                                If your birthday falls within the 30 days after you join, you'll receive your birthday bonus 30 days after your signup. This delay only applies to the first year of your registration.
                            </p>
                            <p>
                                In all subsequent years, you'll receive your birthday points on your birthday via email. If you enter your birth date after your birthday has already passed in the current year, you will start receiving
                                your birthday points the following year.
                            </p>
                            <p>
                                Please note: The amount of birthday points depends on your level at the time of your birthday. At the Explorer level, you receive 250 points. At the Pioneer level, you get 1.25 times as many,
                                and at the Trailblazer level, you get 1.5 times as many points.
                            </p>
                            <p>
                                If you haven't received points 30 days after signing up, please contact our customer service at <a href="mailto:info.eu@frontrunneroutfitters.com">info.eu@frontrunneroutfitters.com</a> and we'll be happy to help you.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* VIP Levels and Currency Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                VIP Levels and Currency
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>How is my VIP level determined?</h4>
                            <p>
                                Your VIP level depends on how much you spend in a calendar year. The levels you can reach are as follows:
                            </p>
                            <ul>
                                <li>Explorer: No minimum spend</li>
                                <li>Pioneer: €1,500+ / £1,500+ spend (from 1500 points)</li>
                                <li>Trailblazer: €3,000+ / £3,000+ spend (from 3000 points)</li>
                            </ul>
                            <p>
                                Once you reach the necessary points by spending, you'll automatically be upgraded to a new VIP level. You can use the points you have accumulated until the end of the current and following calendar year. When you upgrade to the next VIP level, you keep all the points from your previous level.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Social Media and Points Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Social Media and Points
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>How do I earn points for social media likes?</h4>
                            <p>
                                To earn points for liking on our Facebook or other social media channels, you must perform the action through our landing page. We track this activity on our site to ensure all points are correctly credited.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Newsletter and GDPR Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Newsletter and Participation in the Loyalty Program
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>After signing up for the Explorer Rewards Program, what newsletters and emails will I receive?</h4>
                            <p>
                                After signing up for the Explorer Rewards Program, you will automatically receive personalized emails tailored to your purchase behavior and status in the Rewards Program. These emails contain personalized information, promotions, and offers. Additionally, you can easily subscribe to the Front Runner Newsletter with more information and offers from our website.
                            </p>

                            <h4>What personal data is collected within the program (GDPR)?</h4>
                            <p>
                                All detailed information on the privacy policy can be found here:
                                { ' ' }
                                <Link to="/privacy-policy">Privacy Policy</Link>
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }

    renderFAQAccordionsENUK() {
        return (
            <div className="faqAccordionWrapper">
                <h2>Terms and Condition</h2>
                <Accordion allowZeroExpanded>
                    { /* Registration Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Registration
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>How do I sign up for the Front Runner Explorer Rewards Program?</h4>
                            <p>
                                Signing up is easy: Create your customer account on our Front Runner website by entering your name, email address, and password. If you already have a customer account, you can proceed directly to the next step.
                            </p>
                            <p>
                                On the Front Runner website, go to "Rewards" in your account settings and click the box “I would like to participate in the free Explorer Rewards loyalty program and agree to the Terms and Conditions and Privacy Policy.” to join the Explorer Rewards Program. You can verify your registration through a confirmation email. Membership is free of charge.
                            </p>

                            <h4>What are the benefits of the Explorer Rewards Program?</h4>
                            <p>
                                The Explorer Rewards Program is tiered: the more you purchase and interact with us, the more loyalty points you accumulate. You can redeem points on future purchases.
                            </p>
                            <p>
                                Additionally, you can earn points by following us on social media, writing product reviews, adding photo or video reviews, and on your birthday! From time to time, we'll add new rewards and benefits to the Explorer Rewards Program.
                            </p>
                            <p>You can find all of the benefits in the graphic overview above.</p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Customer Account Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Customer Account
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>I already have a Front Runner customer account – do I need to create a new one to collect points?</h4>
                            <p>
                                No, you don't! If you already have an active account, follow these steps: Go to “Rewards” in
                                your account and click the box “I would like to participate in the free Explorer Rewards
                                loyalty program and agree to the Terms and Conditions and Privacy Policy.” to join the Explorer
                                Rewards Program. You can verify your registration through a confirmation email.
                            </p>
                            <p>
                                Important: If you have multiple accounts, you can only collect points on one account. Points
                                are only collected in the customer account you use to sign up for the loyalty program. Points
                                cannot be transferred to another customer account.
                            </p>

                            <h4>How can I unsubscribe from the Explorer Rewards Program?</h4>
                            <p>
                                If you want to unsubscribe from the Explorer Rewards, simply uncheck the box under “Rewards”
                                and confirm the unsubscribing through the confirmation email. Your unused points will expire,
                                and you'll also be automatically unsubscribed from the personalized loyalty program newsletter.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Earning and Redeeming Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Earning and Redeeming
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>How can I earn Explorer Rewards points?</h4>
                            <p>Explorer Rewards points can only be collected in our online shop.</p>

                            <h4>How do I redeem my points?</h4>
                            <p>
                                Redeeming your points for discounts, or rewards, is easy! On our website, you'll find all the
                                available options. Once you have reached 400 points, you can redeem your rewards at a minimum
                                purchase value of £40 at checkout. Choose how many points you want to redeem for your purchase
                                and click “UPDATE”. Please note that discounts and other promo codes cannot be combined.
                                Points cannot be redeemed during promotions.
                            </p>
                            <p>
                                <ul>
                                    <li>£20 off at 400 points for a minimum purchase of £40</li>
                                    <li>£40 off at 800 points for a minimum purchase of £80</li>
                                    <li>£60 off at 1200 points for a minimum purchase of £120</li>
                                    <li>£80 off at 1600 points for a minimum purchase of £160</li>
                                    <li>£100 off at 2000 points for a minimum purchase of £200</li>
                                </ul>
                            </p>
                            <p>A maximum of £100 can be redeemed per purchase through Explorer Rewards points.</p>

                            <h4>How many points do I earn per Pound spent?</h4>
                            <p>
                                The Explorer Rewards Program has various levels where you can earn different amounts of points:
                                <ul>
                                    <li>As an Explorer, you earn 1 point for every Pound spent (£1 = 1 point).</li>
                                    <li>As a Pioneer, you earn 1.25 times more points per Pound (£1 = 1.25 points).</li>
                                    <li>As a Trailblazer, you earn 1.5 times more points per Pound (£1 = 1.5 points).</li>
                                </ul>
                            </p>

                            <h4>How quickly can I earn points?</h4>
                            <p>
                                You'll already earn a reward when you join the Explorer Rewards Program. Points can also be
                                earned through purchases, on your birthday, by leaving a review, liking on Instagram, and more.
                                On the Explorer Rewards page, you'll find a full overview of all the ways to earn points.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Returns Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Returns
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>What happens to my points if I return part of my order?</h4>
                            <p>
                                If you purchase for £1,000 but return items worth £900, you will only earn points for the
                                remaining amount (£100). Please note that the return period is 14 days, and points will be
                                credited to your account after this period.
                            </p>

                            <h4>Can I return my order if I redeemed Explorer Rewards points for a discount?</h4>
                            <p>
                                Yes, this is possible. For a full return, you will be refunded the amount paid after the
                                discount, and the redeemed points will be credited back to your Explorer Rewards points balance.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Points Expiration Date Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Points Expiration Date
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Can earned points expire?</h4>
                            <p>
                                Yes, points and VIP levels expire if you don't make a purchase within 12 months. Additionally,
                                all points expire if you end your membership. Points are valid until the end of the calendar year
                                in which you earned them and for the entire following year. For example, points earned in 2024
                                are valid until the end of 2025. If you are inactive for 12 months, all unused points will expire.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Troubleshooting Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Troubleshooting
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Why haven't I received points for my purchase?</h4>
                            <p>
                                Make sure you are logged into your customer account when you make a purchase and don't accidentally
                                check out as a guest. To collect points, you must be logged into your customer account at the time
                                of purchase. If you skipped this step by mistake, please contact us at <a href="mailto:info.eu@frontrunneroutfitters.com">info.eu@frontrunneroutfitters.com</a>,
                                and we'll add the points to your account.
                            </p>
                            <p>
                                Please note that you will not receive additional points for your loyalty program account if you
                                return or cancel your order for any reason. New loyalty points are only awarded for fully completed
                                orders. In case of a return, the points already used for the returned order will, of course, be
                                credited back to your account.
                            </p>

                            <h4>I should have received points for a purchase, but my balance hasn't changed. Were the points credited to me?</h4>
                            <p>
                                Yes! You worked hard for your points, and we want you to be sure they are credited correctly to
                                your account. Please note that it can take up to 15 days for points to be credited to your
                                account after a purchase, and then a few hours before you can redeem them for a discount. If
                                you still feel that points are missing, try the following:
                            </p>
                            <ol>
                                <li>Refresh your browser.</li>
                                <li>Log out of your account and log back in.</li>
                                <li>Check the “Reward History” section in your account. Here you can see how many points you
                                    have earned, when and for what they were credited, as well as all your points redemptions.
                                </li>
                            </ol>
                            <p>
                                If you still feel that your point balance is incorrect, please contact our team at <a href="mailto:info.eu@frontrunneroutfitters.com">info.eu@frontrunneroutfitters.com</a>. We will be happy to check the issue for you.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Birthday Points Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Birthday Points
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>How do I get my birthday points?</h4>
                            <p>
                                Enter your date of birth in your customer account. Loyalty points will automatically be credited
                                to you on your birthday.
                            </p>

                            <h4>I signed up for birthday points but didn't receive them.</h4>
                            <p>
                                Happy Birthday! We're excited you chose to celebrate with us through our loyalty program. To
                                receive your birthday points, you must have entered your date of birth in your account at least
                                30 days before your birthday.
                            </p>
                            <p>
                                If your birthday falls within the 30 days after you join, you'll receive your birthday bonus 30
                                days after your signup. This delay only applies to the first year of your registration.
                            </p>
                            <p>
                                In all subsequent years, you'll receive your birthday points on your birthday via email. If you
                                enter your birth date after your birthday has already passed in the current year, you will start
                                receiving birthday points the following year.
                            </p>
                            <p>
                                Please note: The amount of birthday points depends on your level at the time of your birthday.
                                At the Explorer level, you receive 250 points. At the Pioneer level, you get 1.25 times as many,
                                and at the Trailblazer level, you get 1.5 times as many points.
                            </p>
                            <p>
                                If you haven't received points 30 days after signing up, please contact our customer service at <a href="mailto:info.eu@frontrunneroutfitters.com">info.eu@frontrunneroutfitters.com</a> and we'll be happy to help you.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* VIP Levels and Currency Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                VIP Levels and Currency
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>How is my VIP level determined?</h4>
                            <p>
                                Your VIP level depends on how much you spend in a calendar year. The levels you can reach are
                                as follows:
                            </p>
                            <ul>
                                <li>Explorer: No minimum spend</li>
                                <li>Pioneer: €1,500+ / £1,500+ spend (from 1500 points)</li>
                                <li>Trailblazer: €3,000+ / £3,000+ spend (from 3000 points)</li>
                            </ul>
                            <p>
                                Once you reach the required points, you'll be automatically upgraded to the next VIP level. You
                                can use the points you have accumulated until the end of the current and following calendar
                                year. When you upgrade to a higher VIP level, you keep all the points from your previous level.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Social Media and Points Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Social Media and Points
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>How do I earn points for liking on social media?</h4>
                            <p>
                                To earn points for liking on our Facebook or other social media channels, you must perform the
                                action through our landing page. We track this activity on our site to ensure the points are
                                correctly credited.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Newsletter and GDPR Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Newsletter and Participation in the Loyalty Program
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>After signing up for the Explorer Rewards Program, what newsletters and emails will I receive?</h4>
                            <p>
                                After signing up for the Explorer Rewards Program, you will automatically receive personalized
                                emails tailored to your purchase behavior and status in the Rewards Program. These emails
                                contain personalized information, promotions, and offers. Additionally, you can easily subscribe
                                to the Front Runner Newsletter with more information and offers on our website.
                            </p>

                            <h4>What personal data is collected by the program (GDPR)?</h4>
                            <p>
                                All detailed information on the privacy policy can be found here:
                                { ' ' }
                                <Link to="/privacy-policy">Privacy Policy</Link>.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }

    renderFAQAccordionsDEDE() {
        return (
            <div className="faqAccordionWrapper">
                <h2>Allgemeine Geschäftsbedingungen</h2>
                <Accordion allowZeroExpanded>
                    { /* Anmeldung Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Anmeldung
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Wie melde ich mich für das Front Runner Explorer Rewards Programm an?</h4>
                            <p>
                                Die Anmeldung ist ganz einfach: Erstelle Dein Kundenkonto auf unserer Front Runner Website, indem
                                Du Deinen Namen, Deine E-Mail-Adresse und ein Passwort eingibst. Wenn Du bereits ein Kundenkonto
                                erstellt hast, kannst Du direkt zum nächsten Schritt übergehen.
                            </p>
                            <p>
                                Auf der Front Runner Website wählst Du in Deinem Konto unter dem Navigationspunkt “Treueprogramm”
                                die Checkbox “Ich möchte am kostenlosen Explorer Rewards Treueprogramm teilnehmen und stimme den
                                Allgemeinen Geschäftsbedingungen sowie den Datenschutzbestimmungen zu.” aus, um an dem Explorer
                                Rewards Programm teilzunehmen. Über eine Bestätigungs-E-Mail kannst Du Deine Anmeldung verifizieren.
                                Die Mitgliedschaft ist kostenlos.
                            </p>

                            <h4>Welche Vorteile bietet das Explorer Rewards Programm?</h4>
                            <p>
                                Das Explorer Rewards Programm ist gestaffelt: Je mehr Du bei uns kaufst und interagierst, desto
                                mehr Treuepunkte sammelst Du. Diese Punkte kannst Du bei zukünftigen Einkäufen einlösen.
                            </p>
                            <p>
                                Zusätzlich kannst Du Punkte sammeln, indem Du uns auf Social Media folgst, Produktbewertungen
                                schreibst, Bewertungen mit Fotos oder Videos ergänzt – und sogar, wenn Du Geburtstag hast! Von
                                Zeit zu Zeit werden wir Prämien und Vorteile zu dem Explorer Rewards Programm hinzufügen.
                            </p>
                            <p>Alle Benefits findest Du oberhalb in der grafischen Übersicht.</p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Kundenkonto Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Kundenkonto
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Ich habe bereits ein Front Runner Kundenkonto – muss ich ein neues erstellen, um Punkte zu sammeln?</h4>
                            <p>
                                Nein, das musst Du nicht! Wenn Du bereits ein aktives Konto bei uns hast, sind nur folgende Schritte
                                notwendig: Gehe in der Navigation auf “Treueprogramm” und wähle dort die Checkbox “Ich möchte am
                                kostenlosen Explorer Rewards Treueprogramm teilnehmen und stimme den Allgemeinen Geschäftsbedingungen
                                sowie den Datenschutzbestimmungen zu” aus, um dem Explorer Rewards Programm beizutreten. Über eine
                                Bestätigungs-E-Mail kannst Du Deine Anmeldung verifizieren.
                            </p>
                            <p>
                                Wichtig: Falls Du mehrere Kundenkonten hast, kannst Du nur auf einem Konto Punkte sammeln. Die Punkte
                                werden ausschließlich auf dem Kundenkonto gesammelt, mit dem Du Dich für das Treueprogramm anmeldest.
                                Eine Übertragung der Punkte auf ein anderes Kundenkonto ist nicht möglich.
                            </p>

                            <h4>Wie kann ich mich vom Explorer Rewards Programm wieder abmelden?</h4>
                            <p>
                                Wenn Du Dich von den Explorer Rewards abmelden möchtest, entferne einfach unter dem Navigationspunkt
                                “Treueprogramm“ das Häkchen in der Checkbox und bestätige die Abmeldung über die Bestätigungs-E-Mail.
                                Deine nicht eingelösten Punkte verfallen damit. Außerdem wirst Du automatisch vom personalisierten
                                Newsletter für unser Treueprogramm abgemeldet.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Sammeln und Einlösen Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Sammeln und Einlösen
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Wie kann ich Explorer Rewards Punkte sammeln?</h4>
                            <p>Explorer Rewards Punkte kannst Du ausschließlich in unserem Online Shop sammeln.</p>

                            <h4>Wie löse ich meine Punkte ein?</h4>
                            <p>
                                Das Einlösen Deiner Punkte für Rabatte, auch sogenannte Prämien, ist ganz einfach! Auf unserer
                                Website findest Du alle verfügbaren Optionen. Ab einer Punktzahl von 400 Punkten kannst Du Rabatte,
                                also Deine Prämien, ab einem Einkaufswert von 40 Euro beim Checkout einlösen. Wähle aus, wie viele
                                Punkte Du bei Deinem Einkauf einlösen möchtest und klicke auf „EINLÖSEN“. Beachte, dass Rabatte und
                                andere Gutscheincodes nicht gleichzeitig genutzt werden können. Du kannst Deine Punkte nicht für
                                Einkäufe während Werbeaktionen/Promotions eingelösen.
                            </p>
                            <p>
                                <ul>
                                    <li>20 Euro Rabatt bei 400 Punkten, ab einem Einkaufswert von 40 Euro</li>
                                    <li>40 Euro Rabatt bei 800 Punkten, ab einem Einkaufswert von 80 Euro</li>
                                    <li>60 Euro Rabatt bei 1200 Punkten, ab einem Einkaufswert von 120 Euro</li>
                                    <li>80 Euro Rabatt bei 1600 Punkten, ab einem Einkaufswert von 160 Euro</li>
                                    <li>100 Euro Rabatt bei 2000 Punkten, ab einem Einkaufswert von 200 Euro</li>
                                </ul>
                            </p>
                            <p>Es können pro Kauf maximal 100 Euro Rabatt durch die Explorer Rewards Punkte eingelöst werden.</p>

                            <h4>Wie viele Punkte bekomme ich pro ausgegebenen Euro?</h4>
                            <p>
                                Bei dem Explorer Rewards Programm gibt es verschiedene Stufen, bei denen Du unterschiedlich viele
                                Punkte sammeln kannst:
                                <ul>
                                    <li>Als Entdecker erhältst Du 1 Punkt für jeden ausgegebenen Euro (1 Euro = 1 Punkt).</li>
                                    <li>Als Pioneer sammelst Du 1,25-mal so viele Punkte pro Euro (1 Euro = 1,25 Punkte).</li>
                                    <li>Als Pfadfinder erhältst Du 1,5-mal so viele Punkte pro Euro (1 Euro = 1,5 Punkte).</li>
                                </ul>
                            </p>

                            <h4>Wie schnell kann ich Punkte sammeln?</h4>
                            <p>
                                Du erhältst bereits eine Prämie, wenn Du dem Explorer Rewards Programm beitrittst. Punkte kannst
                                Du auch durch Einkäufe, an Deinem Geburtstag, durch das Hinterlassen einer Bewertung oder das
                                Liken auf Instagram und vieles mehr sammeln. Auf der Seite der Explorer Rewards findest Du alle
                                Möglichkeiten, um Punkte zu sammeln, in der Übersicht.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Returns Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Rücksendungen
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Was passiert mit meinen Punkten, wenn ich einen Teil meiner Bestellung zurücksende?</h4>
                            <p>
                                Wenn Du beispielsweise für 1.000 Euro einkaufst, aber Artikel im Wert von 900 Euro zurücksendest,
                                erhältst Du nur Punkte für den verbleibenden Betrag (100 Euro). Beachte, dass das Rückgaberecht
                                14 Tage beträgt und Punkte erst nach Ablauf dieser Frist Deinem Kundenkonto gutgeschrieben werden.
                            </p>

                            <h4>Kann ich meine Bestellung zurücksenden, wenn ich für einen Rabatt meine Explorer Rewards-Punkte eingelöst habe?</h4>
                            <p>
                                Ja, das ist möglich. Bei einer vollständigen Rücksendung erhältst Du den Betrag, den Du nach Abzug
                                des Rabattes gezahlt hast, erstattet und die eingelösten Punkte werden Deinem Explorer Rewards
                                Punktestand wieder gutgeschrieben.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Expiration Date Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Verfallsdatum von Punkten
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Können gesammelte Punkte verfallen?</h4>
                            <p>
                                Ja, Punkte und VIP-Stufen verfallen, wenn Du innerhalb von 12 Monaten keinen Kauf tätigst.
                                Außerdem verfallen alle Punkte, wenn Du Deine Mitgliedschaft beendest. Punkte sind bis zum Ende
                                des Kalenderjahres, in dem Du sie verdient hast, und für das gesamte folgende Jahr gültig.
                                Beispiel: Punkte, die Du 2024 gesammelt hast, sind bis Ende 2025 gültig. Solltest Du 12 Monate
                                lang inaktiv sein, verfallen alle nicht genutzten Punkte.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Troubleshooting Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Problemlösung
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Warum habe ich keine Punkte für meinen Einkauf erhalten?</h4>
                            <p>
                                Stelle sicher, dass Du bei Deinem Einkauf in Dein Kundenkonto eingeloggt warst und nicht versehentlich
                                als Gast eine Bestellung aufgegeben hast. Um Punkte sammeln zu können, musst Du beim Kauf mit Deinem
                                Kundenkonto angemeldet sein. Falls Du diesen Schritt versehentlich übersprungen hast, kontaktiere uns
                                unter <a href="mailto:info.eu@frontrunneroutfitters.com">info.eu@frontrunneroutfitters.com</a>, damit wir die Punkte nachträglich Deinem Konto gutschreiben können.
                            </p>
                            <p>
                                Bitte beachte, dass Du für Dein Benutzerkonto im Treueprogramm keine zusätzlichen/neuen Punkte
                                erhältst, wenn Du Deine Bestellung aus irgendeinem Grund zurückgibst oder stornierst. Neue Treuepunkte
                                werden nur für vollständig abgeschlossene Bestellungen vergeben. Bei einer Rückgabe werden Dir die
                                bereits vergebenen Punkte der zurückgegebenen Bestellung selbstverständlich wieder auf Deinem
                                Benutzerkonto gutgeschrieben.
                            </p>

                            <h4>Ich sollte Punkte für einen Einkauf erhalten haben, aber mein Guthaben hat sich nicht geändert. Wurden mir die Punkte gutgeschrieben?</h4>
                            <p>
                                Ja! Du hast hart für Deine Punkte gearbeitet und wir möchten, dass Du sicher sein kannst, dass sie
                                korrekt in Deinem Konto verbucht werden. Beachte bitte, dass es bis zu 15 Tage dauern kann, bis die
                                Punkte für einen Einkauf Deinem Konto gutgeschrieben werden und danach einige Stunden dauern kann, bis
                                Du sie als Rabatt einlösen kannst. Wenn Du das Gefühl hast, dass Dir trotzdem Punkte fehlen, versuche
                                Folgendes:
                            </p>
                            <ol>
                                <li>Aktualisiere Deinen Browser.</li>
                                <li>Melde Dich aus Deinem Konto ab und wieder an.</li>
                                <li>Überprüfe den Abschnitt „Belohnungshistorie“ in Deinem Konto. Dort siehst Du, wie viele Punkte Du
                                    erhalten hast, wann und wofür sie gutgeschrieben wurden sowie alle Einlösungen Deiner Punkte.
                                </li>
                            </ol>
                            <p>
                                Solltest Du weiterhin das Gefühl haben, dass Dein Punktestand nicht korrekt ist, kannst Du unser Team
                                unter <a href="mailto:info.eu@frontrunneroutfitters.com">info.eu@frontrunneroutfitters.com</a> kontaktieren. Wir werden das Problem gerne für Dich prüfen.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Geburtstagspunkte Section */ }
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Geburtstagspunkte
                            <AccordionItemState>
                                { this.getItemState }
                            </AccordionItemState>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <h4>Wie erhalte ich meine Geburtstagspunkte?</h4>
                        <p>
                            Trage Dein Geburtsdatum in Deinem Kundenkonto ein. An Deinem Geburtstag werden Dir automatisch
                            die Treuepunkte gutgeschrieben.
                        </p>

                        <h4>Ich habe mich für die Geburtstagspunkte angemeldet, habe sie jedoch nicht erhalten.</h4>
                        <p>
                            Herzlichen Glückwunsch zum Geburtstag! Wir freuen uns, dass Du Dich entschieden hast, mit uns
                            über unser Treueprogramm zu feiern. Damit Du Deine Geburtstagspunkte erhältst, musst Du Dein
                            Geburtsdatum mindestens 30 Tage vor Deinem Geburtstag in Deinem Konto hinterlegt haben.
                        </p>
                        <p>
                            Fällt Dein Geburtstag in den Zeitraum zwischen Deiner Anmeldung und den 30 Tagen danach,
                            erhältst Du Deine Geburtstagsboni 30 Tage nach Deiner Anmeldung. Diese Verzögerung gilt nur für
                            das erste Jahr Deiner Anmeldung.
                        </p>
                        <p>
                            In allen folgenden Jahren erhältst Du Deine Geburtstagspunkte pünktlich an Deinem Geburtstag
                            per E-Mail. Wenn Du Dein Geburtsdatum einträgst, nachdem Dein Geburtstag im laufenden Jahr
                            bereits war, erhältst Du die Treuepunkte erst ab dem darauffolgenden Jahr.
                        </p>
                        <p>
                            Bitte beachte: Die Höhe der Geburtstagspunkte richtet sich nach der Stufe, in der Du Dich an
                            Deinem Geburtstag befindest. In der Entdecker-Stufe erhältst Du 250 Punkte. In der Pioneer-Stufe
                            gibt es 1,25-mal und in der Pfadfinder-Stufe 1,5-mal so viele Punkte.
                        </p>
                        <p>
                            Solltest Du 30 Tage nach Deiner Anmeldung keine Punkte erhalten haben, kontaktiere bitte
                            unseren Kundenservice <a href="mailto:info.eu@frontrunneroutfitters.com">info.eu@frontrunneroutfitters.com</a> und wir helfen Dir gerne weiter.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                { /* VIP-Stufen und Währungen Section */ }
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            VIP-Stufen und Währungen
                            <AccordionItemState>
                                { this.getItemState }
                            </AccordionItemState>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <h4>Wie wird meine VIP-Stufe bestimmt?</h4>
                        <p>
                            Deine VIP-Stufe hängt davon ab, wieviel Du innerhalb eines Kalenderjahres an Umsatz erzielt
                            hast und ausgibst. Die Stufen erreichst Du wie folgt:
                        </p>
                        <ul>
                            <li>Entdecker: Kein Mindestumsatz</li>
                            <li>Pioneer: €1.500+ / £ 1.500+ Umsatz (ab 1500 Punkte)</li>
                            <li>Pfadfinder: €3.000+ / £ 3.000+ Umsatz (ab 3000 Punkte)</li>
                        </ul>
                        <p>
                            Sobald Du die entsprechende Punktezahl erreichst, wirst Du automatisch hochgestuft in die neue
                            VIP-Stufe. Die bisher angesammelten Punkte kannst Du bis zum Ende des aktuellen und des
                            folgenden Kalenderjahres nutzen. Wenn Du in eine höhere VIP-Stufe aufsteigst, behältst Du alle
                            Punkte Deiner vorherigen Stufe.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                { /* Social Media und Punkte Section */ }
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Social Media und Punkte
                            <AccordionItemState>
                                { this.getItemState }
                            </AccordionItemState>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <h4>Wie verdiene ich Punkte für das Liken auf Social Media?</h4>
                        <p>
                            Um Punkte für das Liken auf Facebook oder anderen Social Media-Kanälen zu verdienen, musst Du
                            über unsere Landing Page die Aktion ausführen. Wir tracken diese Aktivität über unsere Seite,
                            um sicherzustellen, dass Dir die Punkte korrekt gutgeschrieben werden.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                { /* Newsletter und Teilnahme am Treueprogramm Section */ }
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Newsletter und Teilnahme am Treueprogramm
                            <AccordionItemState>
                                { this.getItemState }
                            </AccordionItemState>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        {/* <h4>Nach Deiner Anmeldung für das Explorer Rewards Programm, welche E-Mails werde ich erhalten?</h4> */}
                        <h4>Welche E-Mails werde ich nach meiner Anmeldung für das Front Runner Rewards Programm erhalten?</h4>
                        <p>
                            Nach Deiner Anmeldung für das Explorer Rewards Programm erhältst Du automatisch personalisierte
                            E-Mails, die speziell auf Dein Kaufverhalten und Deinen Status im Rewards-Programm abgestimmt
                            sind. Diese E-Mails beinhalten personalisierte Informationen, Aktionen und Angebote. Zusätzlich
                            kannst Du den Front Runner Newsletter, der weitere Informationen und Angebote enthält, ganz
                            einfach über unsere Website abonnieren.
                        </p>

                        <h4>Welche Daten werden von mir innerhalb des Programms gesammelt (DSGVO)?</h4>
                        <p>
                            Alle genauen Informationen zu den Datenschutzbestimmungen findest du hier:
                            { ' ' }
                            <Link to="/privacy-policy">Datenschutzbestimmungen</Link>.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                </Accordion>
            </div>
        );
    }

    renderFAQAccordionsFRBE() {
        return (
            <div className="faqAccordionWrapper">
                <h2>Conditions Générales</h2>
                <Accordion allowZeroExpanded>
                    { /* Inscription Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Inscription
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Comment puis-je m'inscrire au programme de fidélité Front Runner Explorer Rewards ?</h4>
                            <p>
                                L'inscription est simple : crées ton compte client sur notre site Web Front Runner en saisissant
                                ton nom, ton adresse e-mail et un mot de passe. Si tu as déjà un compte client, tu peux passer
                                directement à l'étape suivante.
                            </p>
                            <p>
                                Sur le site Front Runner, vas dans “RÉCOMPENSES” dans les paramètres de ton compte et coches la
                                case “Je souhaite participer au programme de fidélité Explorer Rewards gratuit et j'accepte les
                                Conditions générales ainsi que la Politique de confidentialité.” pour rejoindre le programme
                                Explorer Rewards. Tu peux vérifier ton inscription par e-mail de confirmation. L'adhésion est gratuite.
                            </p>

                            <h4>Quels sont les avantages du programme Explorer Rewards ?</h4>
                            <p>
                                Le programme Explorer Rewards est structuré par paliers : plus tu achètes et interagis avec nous,
                                plus tu cumules de points de fidélité. Ces points peuvent être utilisés pour tes achats futurs.
                            </p>
                            <p>
                                De plus, tu peux gagner des points en nous suivant sur les réseaux sociaux, en rédigeant des avis
                                produits, en ajoutant des photos ou des vidéos à tes avis – et même le jour de ton anniversaire !
                                De temps en temps, nous ajouterons de nouvelles récompenses et avantages au programme Explorer Rewards.
                            </p>
                            <p>Tu trouveras tous les avantages dans le récapitulatif graphique ci-dessus.</p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Compte Client Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Compte Client
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>J'ai déjà un compte client Front Runner – dois-je en créer un nouveau pour accumuler des points ?</h4>
                            <p>
                                Non, ce n'est pas nécessaire ! Si tu as déjà un compte actif, suis ces étapes : accédes à
                                “RÉCOMPENSES” dans ton compte et coche la case “Je souhaite participer au programme de fidélité
                                Explorer Rewards gratuit et j'accepte les Conditions générales ainsi que la Politique de
                                confidentialité.” pour rejoindre le programme Explorer Rewards. Tu pourras vérifier ton inscription
                                par un e-mail de confirmation.
                            </p>
                            <p>
                                Important : si tu as plusieurs comptes, tu ne peux accumuler des points que sur un seul. Les points
                                sont uniquement crédités sur le compte client que tu utilises pour t'inscrire au programme de
                                fidélité. Les points ne peuvent pas être transférés à un autre compte client.
                            </p>

                            <h4>Comment puis-je me désinscrire du programme Explorer Rewards ?</h4>
                            <p>
                                Si tu souhaites te désinscrire du programme Explorer Rewards, il te suffit de décocher la case dans
                                “RÉCOMPENSES” et de confirmer ta désinscription par e-mail de confirmation. Tes points non utilisés
                                expireront, et tu seras également automatiquement désinscrit de la newsletter personnalisée du
                                programme de fidélité.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Accumuler et Utiliser des Points Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Accumuler et Utiliser des Points
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Comment puis-je accumuler des points Explorer Rewards ?</h4>
                            <p>Les points Explorer Rewards peuvent uniquement être accumulés dans notre boutique en ligne.</p>

                            <h4>Comment puis-je utiliser mes points ?</h4>
                            <p>
                                Utiliser tes points pour obtenir des réductions ou des récompenses est facile ! Sur notre site
                                Web, tu trouveras toutes les options disponibles. Dès 400 points, tu peux échanger tes récompenses
                                pour un achat minimum de 40 euros lors du paiement. Choisis combien de points tu souhaites utiliser
                                pour ton achat et cliques sur “METTRE À JOUR”. Note que les réductions et autres codes promo ne
                                peuvent pas être combinés. Les points ne peuvent pas être utilisés lors de promotions.
                            </p>
                            <p>
                                <ul>
                                    <li>20 euros de réduction avec 400 points pour un achat minimum de 40 euros</li>
                                    <li>40 euros de réduction avec 800 points pour un achat minimum de 80 euros</li>
                                    <li>60 euros de réduction avec 1200 points pour un achat minimum de 120 euros</li>
                                    <li>80 euros de réduction avec 1600 points pour un achat minimum de 160 euros</li>
                                    <li>100 euros de réduction avec 2000 points pour un achat minimum de 200 euros</li>
                                </ul>
                            </p>
                            <p>Un maximum de 100 euros peut être échangé par achat via les points Explorer Rewards.</p>

                            <h4>Combien de points gagne-t-on par euro dépensé ?</h4>
                            <p>
                                Le programme Explorer Rewards comporte plusieurs niveaux où tu peux accumuler des points à
                                différents taux:
                                <ul>
                                    <li>En tant qu'Explorateur, tu gagnes 1 point par euro dépensé (1 euro = 1 point).</li>
                                    <li>En tant que Pionnier, tu gagnes 1,25 fois plus de points par euro (1 euro = 1,25 point).</li>
                                    <li>En tant que Trailblazer, tu gagnes 1,5 fois plus de points par euro (1 euro = 1,5 point).</li>
                                </ul>
                            </p>

                            <h4>À quelle vitesse puis-je accumuler des points ?</h4>
                            <p>
                                Tu recevras déjà une récompense lorsque tu rejoins le programme Explorer Rewards. Tu peux également
                                accumuler des points par tes achats, le jour de ton anniversaire, en laissant un avis, en nous
                                suivant sur Instagram, et plus encore. Sur la page Explorer Rewards, tu trouveras un récapitulatif
                                complet des moyens d'accumuler des points.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Retours Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Retours
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Que se passe-t-il avec mes points si je retourne une partie de ma commande ?</h4>
                            <p>
                                Si tu achetes pour 1 000 euros mais retournes des articles d'une valeur de 900 euros, tu gagneras
                                uniquement des points pour le montant restant (100 euros). Note que le délai de retour est de 14
                                jours et que les points ne seront crédités sur ton compte qu'après cette période.
                            </p>

                            <h4>Puis-je retourner ma commande si j'ai utilisé des points Explorer Rewards pour une réduction ?</h4>
                            <p>
                                Oui, c'est possible. Pour un retour complet, le montant payé après la réduction te seras remboursé,
                                et les points utilisés seront recrédités sur ton solde de points Explorer Rewards.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Date d'Expiration des Points Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Date d'Expiration des Points
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Les points accumulés peuvent-ils expirer ?</h4>
                            <p>
                                Oui, les points et les niveaux VIP expirent si tu ne fais aucun achat pendant 12 mois. De plus,
                                tous les points expirent si tu mets fin à ton adhésion. Les points sont valables jusqu'à la fin
                                de l'année civile où ils ont été gagnés et pour toute l'année suivante. Par exemple, les points
                                gagnés en 2024 sont valables jusqu'à fin 2025. Si tu es inactif pendant 12 mois, tous les points
                                non utilisés expireront.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Dépannage Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Dépannage
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Pourquoi n'ai-je pas reçu de points pour mon achat ?</h4>
                            <p>
                                Assures-toi que tu es connecté à ton compte client lors de l'achat et que tu n'as pas accidentellement
                                finalisé ton commande en tant qu'invité. Pour accumuler des points, tu devas être connecté à ton compte
                                client au moment de l'achat. Si tu as omis cette étape, contacte nous à <a href="mailto:info.eu@frontrunneroutfitters.com">info.eu@frontrunneroutfitters.com</a>,
                                et nous ajouterons les points à ton compte.
                            </p>
                            <p>
                                Note que tu ne recevras pas de points supplémentaires pour ton compte du programme de fidélité si tu
                                retournes ou annules ta commande pour quelque raison que ce soit. De nouveaux points de fidélité sont
                                uniquement accordés pour les commandes entièrement complétées. En cas de retour, les points utilisés
                                pour la commande retournée seront, bien entendu, recrédités sur ton compte.
                            </p>

                            <h4>J'aurais dû recevoir des points pour un achat, mais mon solde n'a pas changé. Mes points ont-ils été crédités ?</h4>
                            <p>
                                Oui ! Tu as travaillé dur pour obtenir tes points, et nous voulons que tu sois sûr qu'ils sont correctement
                                crédités sur ton compte. Note qu'il peut falloir jusqu'à 15 jours pour que les points soient crédités
                                après un achat, et ensuite quelques heures avant de pouvoir les utiliser comme réduction. Si tu penses
                                que des points manquent, essayes les étapes suivantes :
                            </p>
                            <ol>
                                <li>Actualises ton navigateur.</li>
                                <li>Déconnectes-toi de ton compte, puis reconnectes-toi.</li>
                                <li>Consultes la section “Historique des récompenses” de ton compte. Tu y verras le nombre de points
                                    gagnés, leur date et leur raison de crédit, ainsi que toutes tes utilisations de points.
                                </li>
                            </ol>
                            <p>
                                Si tu penses toujours que ton solde de points est incorrect, contacte notre équipe à <a href="mailto:info.eu@frontrunneroutfitters.com">info.eu@frontrunneroutfitters.com</a>.
                                Nous serons ravis de vérifier le problème pour toi.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Points d'Anniversaire Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Points d'Anniversaire
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Comment obtenir mes points d'anniversaire ?</h4>
                            <p>
                                Renseigne ta date de naissance dans ton compte client. Les points de fidélité te seront automatiquement
                                crédités le jour de ton anniversaire.
                            </p>

                            <h4>Je me suis inscrit pour les points d'anniversaire, mais je ne les ai pas reçus.</h4>
                            <p>
                                Joyeux anniversaire ! Nous sommes ravis que tu aies choisi de célébrer avec nous via notre programme de
                                fidélité. Pour recevoir tes points d'anniversaire, tu dois avoir saisi ta date de naissance dans ton
                                compte au moins 30 jours avant ton anniversaire.
                            </p>
                            <p>
                                Si ton anniversaire tombe dans les 30 jours suivant ton inscription, tu recevras ton bonus d'anniversaire
                                30 jours après ton inscription. Ce délai ne s'applique qu'à la première année.
                            </p>
                            <p>
                                Tous les ans, tu recevras tes points d'anniversaire le jour de ton anniversaire par e-mail. Si tu saisis
                                ta date de naissance après ton anniversaire de l'année en cours, tu commenceras à recevoir des points
                                d'anniversaire l'année suivante.
                            </p>
                            <p>
                                Note : le nombre de points d'anniversaire dépend de ton niveau au moment de ton anniversaire. Au niveau
                                Explorateur, tu recois 250 points. Au niveau Pionnier, tu recois 1,25 fois plus de points, et au niveau
                                Trailblazer, 1,5 fois plus de points.
                            </p>
                            <p>
                                Si tu n'as pas reçu de points 30 jours après ton inscription, contacte notre service client à <a href="mailto:info.eu@frontrunneroutfitters.com">info.eu@frontrunneroutfitters.com</a>,
                                et nous serons heureux de t'aider.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Niveaux VIP et Monnaies Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Niveaux VIP et Monnaies
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Comment mon niveau VIP est-il déterminé ?</h4>
                            <p>
                                Ton niveau VIP dépend de la somme totale commandée au cours d'une année civile. Les niveaux sont
                                atteints comme suit :
                            </p>
                            <ul>
                                <li>Explorateur : Aucun chiffre d'affaires minimum</li>
                                <li>Pionnier : 1 500 €+ / 1 500 £+ de chiffre d'affaires (à partir de 1 500 points)</li>
                                <li>Trailblazer : 3 000 €+ / 3 000 £+ de chiffre d'affaires (à partir de 3 000 points)</li>
                            </ul>
                            <p>
                                Une fois le nombre de points requis atteint, tu passes automatiquement au niveau VIP supérieur. Les
                                points accumulés peuvent être utilisés jusqu'à la fin de l'année en cours et de l'année suivante.
                                Lorsque tu atteins un niveau VIP supérieur, tu conserveras tous les points de ton niveau précédent.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Réseaux Sociaux et Points Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Réseaux Sociaux et Points
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Comment gagner des points en aimant sur les réseaux sociaux ?</h4>
                            <p>
                                Pour gagner des points en aimant sur Facebook ou d'autres réseaux sociaux, tu dois effectuer l'action
                                via notre page de renvoi. Nous suivons cette activité via notre site pour garantir que tes points
                                sont correctement crédités.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Newsletter et RGPD Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Newsletter et Participation au Programme de Fidélité
                                <AccordionItemState>
                                    { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Après ton inscription au programme Explorer Rewards, quelles e-mails vais-je recevoir ?</h4>
                            <p>
                                Après ton inscription au programme Explorer Rewards, tu recevras automatiquement des e-mails personnalisés
                                en fonction de tes achats et de ton statut dans le programme de fidélité. Ces e-mails contiennent des
                                informations personnalisées, des offres et des promotions. Tu peux également t'abonner à la newsletter
                                Front Runner pour recevoir d'autres informations et offres directement sur notre site.
                            </p>

                            <h4>Quelles données sont collectées dans le cadre du programme (RGPD) ?</h4>
                            <p>
                                Toutes les informations détaillées sur la politique de confidentialité se trouvent ici :
                                { ' ' }
                                <Link to="/privacy-policy">Politique de Confidentialité</Link>.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }

    renderFAQAccordionsESBE() {
        return (
            <div className="faqAccordionWrapper">
                <h2>Términos y condiciones</h2>
                <Accordion allowZeroExpanded>

                    { /* Inscripción Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Inscripción
                                <AccordionItemState>
                                { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>¿Cómo me inscribo en el programa de recompensas Front Runner Explorer Rewards?</h4>
                            <p>
                                La inscripción es muy sencilla: crea tu cuenta de cliente en nuestro sitio web de Front Runner ingresando tu nombre, dirección de correo electrónico y una contraseña. Si ya tienes una cuenta de cliente, puedes pasar directamente al siguiente paso. En el sitio web de Front Runner, selecciona "RECOMPENSAS" en tu cuenta y marca la casilla "Me gustaría participar en el programa de fidelización gratuito Explorer Rewards y acepto los Términos y Condiciones y la Política de privacidad." para unirte al programa Explorer Rewards. Puedes verificar tu inscripción a través de un correo electrónico de confirmación. La membresía es gratuita.
                            </p>
                            <h4>¿Cuáles son los beneficios del programa Explorer Rewards?</h4>
                            <p>
                                El programa Explorer Rewards está estructurado en niveles: cuanto más compres e interactúes con nosotros, más puntos de fidelidad acumularás. Puedes canjear estos puntos en tus compras futuras. Además, puedes ganar puntos siguiendo nuestras cuentas en redes sociales, escribiendo reseñas de productos, añadiendo fotos o videos a tus reseñas e incluso en tu cumpleaños. De vez en cuando, añadiremos nuevas recompensas y beneficios al programa Explorer Rewards. Puedes ver todos los beneficios en el resumen gráfico arriba.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Cuenta de Cliente Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Cuenta de Cliente
                                <AccordionItemState>
                                { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Ya tengo una cuenta de cliente en Front Runner. ¿Debo crear una nueva para acumular puntos?</h4>
                            <p>
                                No, ¡no es necesario! Si ya tienes una cuenta activa, solo debes seguir estos pasos: en tu cuenta, accede a "RECOMPENSAS" y marca la casilla "Me gustaría participar en el programa de fidelización gratuito Explorer Rewards y acepto los Términos y Condiciones y la Política de privacidad." para unirte al programa Explorer Rewards. Puedes verificar tu inscripción mediante un correo electrónico de confirmación.
                            </p>
                            <p>
                                Importante: si tienes varias cuentas de cliente, solo podrás acumular puntos en una. Los puntos se acreditarán exclusivamente en la cuenta de cliente que utilices para registrarte en el programa de fidelidad. No es posible transferir puntos a otra cuenta de cliente.
                            </p>
                            <h4>¿Cómo puedo cancelar mi suscripción al programa Explorer Rewards?</h4>
                            <p>
                                Si deseas cancelar tu suscripción al programa Explorer Rewards, simplemente desmarca la casilla en "RECOMPENSAS" y confirma la cancelación mediante el correo electrónico de confirmación. Tus puntos no utilizados expirarán, y también serás dado de baja automáticamente del boletín personalizado del programa de fidelidad.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Acumulación y Canje de Puntos Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Acumulación y Canje de Puntos
                                <AccordionItemState>
                                { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>¿Cómo puedo acumular puntos Explorer Rewards?</h4>
                            <p>Puedes acumular puntos Explorer Rewards exclusivamente en nuestra tienda en línea.</p>
                            <h4>¿Cómo canjeo mis puntos?</h4>
                            <p>
                                Canjear tus puntos por descuentos o recompensas es muy fácil. En nuestro sitio web, encontrarás todas las opciones disponibles. A partir de 400 puntos, puedes canjear tus recompensas para una compra mínima de 40 euros al finalizar el pedido. Selecciona cuántos puntos deseas usar y haz clic en "ACTUALIZAR". Ten en cuenta que los descuentos y otros códigos promocionales no se pueden combinar. Tampoco puedes canjear puntos en compras durante promociones.
                            </p>
                            <ul>
                                <li>20 euros de descuento con 400 puntos, para una compra mínima de 40 euros</li>
                                <li>40 euros de descuento con 800 puntos, para una compra mínima de 80 euros</li>
                                <li>60 euros de descuento con 1200 puntos, para una compra mínima de 120 euros</li>
                                <li>80 euros de descuento con 1600 puntos, para una compra mínima de 160 euros</li>
                                <li>100 euros de descuento con 2000 puntos, para una compra mínima de 200 euros</li>
                            </ul>
                            <p>Se puede aplicar un máximo de 100 euros de descuento por compra mediante puntos Explorer Rewards.</p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Devoluciones Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Devoluciones
                                <AccordionItemState>
                                { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>¿Qué pasa con mis puntos si devuelvo parte de mi pedido?</h4>
                            <p>
                                Si compras por un total de 1.000 euros y devuelves artículos por valor de 900 euros, solo acumularás puntos por el importe restante (100 euros). Ten en cuenta que el plazo de devolución es de 14 días y que los puntos se acreditarán a tu cuenta después de este periodo.
                            </p>
                            <h4>¿Puedo devolver mi pedido si usé puntos Explorer Rewards para obtener un descuento?</h4>
                            <p>
                                Sí, es posible. En el caso de una devolución completa, recibirás el reembolso de la cantidad pagada después del descuento, y los puntos utilizados se acreditarán de nuevo a tu saldo de puntos Explorer Rewards.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Caducidad de los Puntos Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Caducidad de los Puntos
                                <AccordionItemState>
                                { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>¿Pueden expirar los puntos acumulados?</h4>
                            <p>
                                Sí, los puntos y los niveles VIP expiran si no realizas ninguna compra durante 12 meses. Además, todos los puntos expiran si finalizas tu membresía. Los puntos son válidos hasta el final del año calendario en el que se ganaron y durante todo el año siguiente. Ejemplo: los puntos ganados en 2024 son válidos hasta el final de 2025. Si permaneces inactivo durante 12 meses, todos los puntos no utilizados caducarán.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Resolución de Problemas Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Resolución de Problemas
                                <AccordionItemState>
                                { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>¿Por qué no he recibido puntos por mi compra?</h4>
                            <p>
                                Asegúrate de haber iniciado sesión en tu cuenta de cliente al realizar la compra y de no haber finalizado tu pedido como invitado por error. Para acumular puntos, debes estar registrado en tu cuenta al momento de la compra. Si omitiste este paso, contáctanos en <a href="mailto:info.eu@frontrunneroutfitters.com">info.eu@frontrunneroutfitters.com</a>, y añadiremos los puntos a tu cuenta.
                            </p>
                            <h4>Debí haber recibido puntos por una compra, pero mi saldo no ha cambiado. ¿Se acreditaron mis puntos?</h4>
                            <p>
                                ¡Sí! Has trabajado duro para ganar tus puntos, y queremos que tengas la seguridad de que se han acreditado correctamente en tu cuenta. Ten en cuenta que puede tardar hasta 15 días para que los puntos se acrediten después de una compra y luego algunas horas para que puedan usarse como descuento. Si sientes que faltan puntos, intenta lo siguiente:
                            </p>
                            <ul>
                                <li>Actualiza tu navegador.</li>
                                <li>Cierra sesión y vuelve a iniciar sesión en tu cuenta.</li>
                                <li>Consulta la sección “Historial de recompensas” en tu cuenta. Aquí puedes ver cuántos puntos has ganado, cuándo y para qué se acreditaron, además de todas tus transacciones de canje.</li>
                            </ul>
                            <p>Si sigues pensando que tu saldo de puntos no es correcto, contacta a nuestro equipo en <a href="mailto:info.eu@frontrunneroutfitters.com">info.eu@frontrunneroutfitters.com</a>. Estaremos encantados de ayudarte a resolver el problema.</p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Puntos de Cumpleaños Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Puntos de Cumpleaños
                                <AccordionItemState>
                                { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>¿Cómo obtengo mis puntos de cumpleaños?</h4>
                            <p>Ingresa tu fecha de nacimiento en tu cuenta de cliente. En tu cumpleaños, los puntos de fidelidad se acreditarán automáticamente en tu cuenta.</p>
                            <h4>Me inscribí para obtener puntos de cumpleaños, pero no los he recibido.</h4>
                            <p>
                                ¡Feliz cumpleaños! Nos alegra que hayas elegido celebrar con nosotros a través de nuestro programa de fidelidad. Para recibir tus puntos de cumpleaños, debes haber ingresado tu fecha de nacimiento en tu cuenta al menos 30 días antes de tu cumpleaños. Si tu cumpleaños cae dentro de los 30 días posteriores a tu inscripción, recibirás tu bonificación de cumpleaños 30 días después de registrarte. Este retraso solo aplica para el primer año.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Niveles VIP y Monedas Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Niveles VIP y Monedas
                                <AccordionItemState>
                                { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>¿Cómo se determina mi nivel VIP?</h4>
                            <p>
                                Tu nivel VIP depende del gasto que realices en un año calendario. Los niveles se alcanzan de la siguiente manera:
                            </p>
                            <ul>
                                <li>Explorador: Sin gasto mínimo</li>
                                <li>Pionero: 1.500 €+ / 1.500 £+ en gasto (a partir de 1.500 puntos)</li>
                                <li>Líder: 3.000 €+ / 3.000 £+ en gasto (a partir de 3.000 puntos)</li>
                            </ul>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Redes Sociales y Puntos Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Redes Sociales y Puntos
                                <AccordionItemState>
                                { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>¿Cómo gano puntos al dar "me gusta" en redes sociales?</h4>
                            <p>
                                Para ganar puntos al dar "me gusta" en Facebook u otras redes sociales, debes realizar la acción a través de nuestra página de inicio. Realizamos un seguimiento de esta actividad en nuestro sitio para asegurarnos de que tus puntos se acrediten correctamente.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Newsletter y GDPR Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Newsletter y GDPR
                                <AccordionItemState>
                                { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Newsletter y Participación en el Programa de Fidelidad</h4>
                            <p>
                                Después de registrarte en el programa Explorer Rewards, recibirás automáticamente correos electrónicos personalizados según tu historial de compras y tu estado en el programa de recompensas. Estos correos incluyen información personalizada, ofertas y promociones. También puedes suscribirte al boletín de noticias de Front Runner en nuestro sitio para recibir más información y ofertas.
                            </p>
                            <h4>¿Qué datos se recopilan en el programa (GDPR)?</h4>
                            <p>
                                Puedes encontrar toda la información detallada sobre la política de privacidad aquí:
                                { ' ' }
                                <Link to="/privacy-policy">Política de Privacidad</Link>
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                </Accordion>
            </div>
        );
    }

    renderFAQAccordionsNLBE() {
        return (
            <div className="faqAccordionWrapper">
                 <h2>Algemene Voorwaarden</h2>
                <Accordion allowZeroExpanded>

                    { /* Aanmelden Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Aanmelden
                                <AccordionItemState>
                                { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Hoe meld ik me aan voor het Front Runner Explorer Rewards-programma?</h4>
                            <p>
                                Aanmelden is heel eenvoudig: maak een account aan op onze Front Runner-website door je naam, e-mailadres en een wachtwoord in te voeren. Als je al een account hebt, kun je direct doorgaan naar de volgende stap.
                            </p>
                            <p>
                                Op de Front Runner-website selecteer je in je account onder “BELONINGEN” het selectievakje “Ik wil graag deelnemen aan het gratis Explorer Rewards loyaliteitsprogramma en ga akkoord met de Algemene Voorwaarden en het Privacybeleid.” om deel te nemen aan het Explorer Rewards-programma. Je kunt je inschrijving bevestigen via een bevestigingsmail. Het lidmaatschap is gratis.
                            </p>
                            <h4>Welke voordelen biedt het Explorer Rewards-programma?</h4>
                            <p>
                                Het Explorer Rewards-programma werkt in niveaus: hoe meer je bij ons koopt en interactie hebt, hoe meer loyaliteitspunten je verzamelt. Deze punten kun je inwisselen bij toekomstige aankopen.
                            </p>
                            <p>
                                Bovendien kun je punten verdienen door ons te volgen op sociale media, productbeoordelingen te schrijven, beoordelingen aan te vullen met foto's of video's – en zelfs op je verjaardag! Van tijd tot tijd zullen we extra beloningen en voordelen toevoegen aan het Explorer Rewards-programma.
                            </p>
                            <p>Alle voordelen kun je bovenaan in het grafische overzicht bekijken.</p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Klantenaccount Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Klantenaccount
                                <AccordionItemState>
                                { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Ik heb al een Front Runner-account – moet ik een nieuwe aanmaken om punten te verzamelen?</h4>
                            <p>
                                Nee, dat hoeft niet! Als je al een actief account bij ons hebt, zijn alleen de volgende stappen nodig: ga in je account naar “BELONINGEN” en selecteer het vakje “Ik wil graag deelnemen aan het gratis Explorer Rewards loyaliteitsprogramma en ga akkoord met de Algemene Voorwaarden en het Privacybeleid.” om deel te nemen aan het Explorer Rewards-programma. Je kunt je inschrijving bevestigen via een bevestigingsmail.
                            </p>
                            <p>
                                Belangrijk: als je meerdere accounts hebt, kun je alleen punten verzamelen op één account. De punten worden uitsluitend verzameld op het account waarmee je je hebt aangemeld voor het loyaliteitsprogramma. Overdracht van punten naar een ander account is niet mogelijk.
                            </p>
                            <h4>Hoe meld ik me af voor het Explorer Rewards-programma?</h4>
                            <p>
                                Als je je wilt afmelden voor het Explorer Rewards-programma, verwijder je eenvoudig het vinkje in het selectievakje onder “Loyaliteitsprogramma” en bevestig je de afmelding via de bevestigingsmail. Je niet-ingewisselde punten komen daarmee te vervallen. Daarnaast word je automatisch afgemeld voor de gepersonaliseerde nieuwsbrief voor ons loyaliteitsprogramma.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Punten Verzamelen en Inwisselen Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Punten Verzamelen en Inwisselen
                                <AccordionItemState>
                                { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Hoe verzamel ik Explorer Rewards-punten?</h4>
                            <p>Explorer Rewards-punten kun je uitsluitend verzamelen in onze online shop.</p>
                            <h4>Hoe wissel ik mijn punten in?</h4>
                            <p>
                                Het inwisselen van je punten voor kortingen of beloningen is heel eenvoudig! Op onze website vind je alle beschikbare opties. Vanaf een saldo van 400 punten kun je je kortingen inwisselen bij een minimale bestelwaarde van 40 euro bij het afrekenen. Kies hoeveel punten je wilt inwisselen en klik op "BIJWERKEN". Let op dat kortingen en andere kortingscodes niet tegelijkertijd gebruikt kunnen worden. Je kunt je punten niet inwisselen bij aankopen tijdens promoties.
                            </p>
                            <ul>
                                <li>20 euro korting bij 400 punten, vanaf een bestelwaarde van 40 euro</li>
                                <li>40 euro korting bij 800 punten, vanaf een bestelwaarde van 80 euro</li>
                                <li>60 euro korting bij 1200 punten, vanaf een bestelwaarde van 120 euro</li>
                                <li>80 euro korting bij 1600 punten, vanaf een bestelwaarde van 160 euro</li>
                                <li>100 euro korting bij 2000 punten, vanaf een bestelwaarde van 200 euro</li>
                            </ul>
                            <p>Per aankoop kan maximaal 100 euro korting worden ingewisseld met Explorer Rewards-punten.</p>
                            <h4>Hoeveel punten krijg ik per uitgegeven euro?</h4>
                            <p>
                                Het Explorer Rewards-programma heeft verschillende niveaus waarmee je op verschillende manieren punten kunt verzamelen:
                            </p>
                            <ul>
                                <li>Als Verkenner ontvang je 1 punt voor elke uitgegeven euro (1 euro = 1 punt).</li>
                                <li>Als Pioneer verzamel je 1,25 keer zoveel punten per euro (1 euro = 1,25 punten).</li>
                                <li>Als Trailblazer ontvang je 1,5 keer zoveel punten per euro (1 euro = 1,5 punten).</li>
                            </ul>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Retourneren Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Retourneren
                                <AccordionItemState>
                                { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Wat gebeurt er met mijn punten als ik een deel van mijn bestelling retourneer?</h4>
                            <p>
                                Als je bijvoorbeeld voor 1.000 euro aan artikelen koopt maar artikelen ter waarde van 900 euro retourneert, krijg je alleen punten voor het resterende bedrag (100 euro). Houd er rekening mee dat de retourperiode 14 dagen is en dat punten pas na deze periode aan je account worden toegevoegd.
                            </p>
                            <h4>Kan ik mijn bestelling retourneren als ik Explorer Rewards-punten heb ingewisseld voor korting?</h4>
                            <p>
                                Ja, dat is mogelijk. Bij een volledige retourzending ontvang je het bedrag terug dat je na aftrek van de korting hebt betaald en worden de ingewisselde punten weer toegevoegd aan je Explorer Rewards-saldo.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Vervaldatum van Punten Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Vervaldatum van Punten
                                <AccordionItemState>
                                { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Kunnen verzamelde punten vervallen?</h4>
                            <p>
                                Ja, punten en VIP-niveaus vervallen als je binnen 12 maanden geen aankoop doet. Daarnaast vervallen alle punten als je je lidmaatschap beëindigt. Punten zijn geldig tot het einde van het kalenderjaar waarin ze zijn verdiend en gedurende het gehele volgende jaar. Voorbeeld: punten die je in 2024 verzamelt, zijn geldig tot eind 2025. Als je 12 maanden inactief bent, komen alle ongebruikte punten te vervallen.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Probleemoplossing Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Probleemoplossing
                                <AccordionItemState>
                                { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Waarom heb ik geen punten ontvangen voor mijn aankoop?</h4>
                            <p>
                                Zorg ervoor dat je bij je aankoop was ingelogd op je account en niet per ongeluk een bestelling als gast hebt geplaatst. Om punten te kunnen verzamelen, moet je tijdens de aankoop ingelogd zijn met je account. Als je deze stap per ongeluk hebt overgeslagen, neem dan contact met ons op via <a href="mailto:info.eu@frontrunneroutfitters.com">info.eu@frontrunneroutfitters.com</a> zodat we de punten met terugwerkende kracht aan je account kunnen toevoegen.
                            </p>
                            <p>
                                Houd er rekening mee dat je geen extra punten voor je loyaliteitsaccount ontvangt als je je bestelling om welke reden dan ook retourneert of annuleert. Nieuwe loyaliteitspunten worden alleen toegekend voor volledig afgeronde bestellingen. Bij een retour worden de al toegewezen punten van de geretourneerde bestelling uiteraard weer op je account bijgeschreven.
                            </p>
                            <h4>Ik zou punten voor een aankoop moeten hebben ontvangen, maar mijn saldo is niet gewijzigd. Zijn de punten wel bijgeschreven?</h4>
                            <p>
                                Ja! Je hebt hard gewerkt voor je punten en we willen dat je zeker weet dat ze correct in je account worden geboekt. Houd er rekening mee dat het tot 15 dagen kan duren voordat de punten voor een aankoop worden bijgeschreven, waarna het enkele uren kan duren voordat je ze als korting kunt inwisselen. Als je het gevoel hebt dat er punten ontbreken, probeer dan het volgende:
                            </p>
                            <ol>
                                <li>Vernieuw je browser.</li>
                                <li>Log uit en log opnieuw in op je account.</li>
                                <li>Controleer de sectie "Beloningsgeschiedenis" in je account. Hier zie je hoeveel punten je hebt ontvangen, wanneer en waarvoor ze zijn bijgeschreven, evenals alle ingewisselde punten.</li>
                            </ol>
                            <p>
                                Als je nog steeds denkt dat je puntensaldo niet correct is, neem dan contact op met ons team via <a href="mailto:info.eu@frontrunneroutfitters.com">info.eu@frontrunneroutfitters.com</a>. We zullen het probleem graag voor je nakijken.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Verjaardagspunten Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Verjaardagspunten
                                <AccordionItemState>
                                { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Hoe ontvang ik mijn verjaardagspunten?</h4>
                            <p>Vul je geboortedatum in op je account. Op je verjaardag worden de loyaliteitspunten automatisch aan je account toegevoegd.</p>
                            <h4>Ik heb me aangemeld voor verjaardagspunten, maar ik heb ze niet ontvangen.</h4>
                            <p>
                                Gefeliciteerd met je verjaardag! We zijn blij dat je ervoor hebt gekozen om met ons te vieren via ons loyaliteitsprogramma. Om je verjaardagspunten te ontvangen, moet je je geboortedatum minstens 30 dagen voor je verjaardag in je account hebben ingevoerd.
                            </p>
                            <p>
                                Als je verjaardag binnen de 30 dagen na je aanmelding valt, ontvang je je verjaardagsbonus 30 dagen na je inschrijving. Deze vertraging geldt alleen voor het eerste jaar.
                            </p>
                            <p>
                                In de daaropvolgende jaren ontvang je je verjaardagspunten op je verjaardag via e-mail. Als je je geboortedatum pas na je verjaardag invoert, ontvang je de verjaardagspunten vanaf het volgende jaar.
                            </p>
                            <p>
                                Opmerking: de hoeveelheid verjaardagspunten hangt af van het niveau waarin je je bevindt op je verjaardag. Op het Verkenners-niveau ontvang je 250 punten. Op het Pioneers-niveau ontvang je 1,25 keer zoveel punten, en op het Trailblazer-niveau 1,5 keer zoveel punten.
                            </p>
                            <p>
                                Als je 30 dagen na je aanmelding nog geen punten hebt ontvangen, neem dan contact op met onze klantenservice <a href="mailto:info.eu@frontrunneroutfitters.com">info.eu@frontrunneroutfitters.com</a> en we helpen je graag verder.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* VIP-niveaus en Munten Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                VIP-niveaus en Munten
                                <AccordionItemState>
                                { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Hoe wordt mijn VIP-niveau bepaald?</h4>
                            <p>
                                Je VIP-niveau hangt af van het bedrag dat je binnen een kalenderjaar hebt besteed. De niveaus bereik je als volgt:
                            </p>
                            <ul>
                                <li>Verkenner: Geen minimale besteding</li>
                                <li>Pioneer: €1.500+ / £1.500+ besteding (vanaf 1.500 punten)</li>
                                <li>Trailblazer: €3.000+ / £3.000+ besteding (vanaf 3.000 punten)</li>
                            </ul>
                            <p>
                                Zodra je het vereiste aantal punten hebt bereikt, word je automatisch naar het nieuwe VIP-niveau gepromoveerd. Je kunt de punten die je tot dat moment hebt verzameld gebruiken tot het einde van het huidige kalenderjaar en het volgende jaar. Als je naar een hoger VIP-niveau stijgt, behoud je alle punten van je vorige niveau.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Social Media en Punten Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Social Media en Punten
                                <AccordionItemState>
                                { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Hoe verdien ik punten door "liken" op social media?</h4>
                            <p>
                                Om punten te verdienen door een “like” te geven op Facebook of andere social mediakanalen, moet je de actie via onze landingspagina uitvoeren. We volgen deze activiteit via onze pagina om ervoor te zorgen dat de punten correct aan je account worden toegevoegd.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    { /* Nieuwsbrief en GDPR Section */ }
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Nieuwsbrief en GDPR
                                <AccordionItemState>
                                { this.getItemState }
                                </AccordionItemState>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <h4>Nieuwsbrief en deelname aan het loyaliteitsprogramma</h4>
                            <p>
                                Na je aanmelding voor het Explorer Rewards-programma ontvang je automatisch gepersonaliseerde e-mails die zijn afgestemd op je koopgedrag en je status in het beloningsprogramma. Deze e-mails bevatten gepersonaliseerde informatie, acties en aanbiedingen. Daarnaast kun je je eenvoudig inschrijven voor de Front Runner nieuwsbrief, die meer informatie en aanbiedingen bevat, via onze website.
                            </p>
                            <h4>Welke gegevens worden verzameld binnen het programma (GDPR)?</h4>
                            <p>
                                Je kunt alle gedetailleerde informatie over het privacybeleid hier vinden:
                                { ' ' }
                                <Link to="/privacy-policy">Privacybeleid</Link>
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                </Accordion>
            </div>
        );
    }

    render() {
        const locale = window.storeConfig.code;

        switch (locale) {
        case 'en_de':
            return this.renderFAQAccordionsENDE();
        case 'en_uk':
            return this.renderFAQAccordionsENUK();
        case 'de_de':
            return this.renderFAQAccordionsDEDE();
        case 'fr_be':
            return this.renderFAQAccordionsFRBE();
        case 'es_be':
            return this.renderFAQAccordionsESBE();
        case 'nl_be':
            return this.renderFAQAccordionsNLBE();
        default:
            return this.renderFAQAccordions(); // Default to English
        }
    }
}

export default YotpoLoyalty;
